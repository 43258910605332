<template>
  <div class="friends">
    <div class="container body-wrap">
      <article id="post-" class="post-article article-type-post fade in" itemprop="blogPost">
        <div class="post-card">
          <h3 class="more-title">友情链接</h3>
          <div class="link-box-area">
            <a class="link-box" v-for="(item,index) in friendsList" :key="index" :href="item.website"  target="_blank">
              <span class="link-name">
                  <img class="link-favicon" :src="item.avatar"/>
                  {{item.title}}
              </span>
              <span class="link-direction">
            {{item.describe}}
              </span>
            </a>
          </div>  <!--class="link-box-area"-->
          <h3 class="more-title">博主的个人收藏夹</h3>
          <div class="link-box-area">
            <a class="link-box" href="https://markdown.lovejade.cn/" target="_blank">
        <span class="link-name">
            <img class="link-favicon" src="https://content.jianguoyun.com/wp-content/uploads/2018/12/Markdown-mark-solid.jpg"/>
            在线Markdown编辑器
        </span>
              <span class="link-direction">
            一款基于 Vue、Vditor的在线 Markdown 编辑器；轻量且强大：内置粘贴 HTML 自动转换为 Markdown，支持流程图、甘特图、时序图、任务列表，可导出携带样式的图片、PDF、微信公众号特制的 HTML 等等。
        </span>
            </a>
            <a class="link-box" href="http://www.52pojie.cn/" target="_blank">
        <span class="link-name">
            <img class="link-favicon" src="https://avatar.52pojie.cn/images/noavatar_small.gif"/>
            吾爱破解论坛
        </span>
              <span class="link-direction">
            吾爱破解论坛致力于软件安全与病毒分析的前沿，由无数热衷于软件加密解密及反病毒爱好者共同维护
        </span>
            </a>
            <a class="link-box" href="https://tool.lu/" target="_blank">
        <span class="link-name">
            <img class="link-favicon" src="https://pic.90sheji.com/design/00/07/85/23/59316fe4b66ae.png"/>
            工具箱
        </span>
              <span class="link-direction">
            程序员必备工具箱，功能十分全！
        </span>
            </a>
            <a class="link-box" href="https://github.com/" target="_blank">
        <span class="link-name">
            <img class="link-favicon" src="https://raw.githubusercontent.com/luoqianyi/luoqianyi/main/images/github.svg"/>
            GitHub
        </span>
              <span class="link-direction">
            The home for all developers — including you.
        </span>
            </a>
            <a class="link-box" href="http://youquhome.com/" target="_blank">
        <span class="link-name">
            <img class="link-favicon" src="https://png.pngtree.com/png-clipart/20190516/original/pngtree-english-alphabet-with-picture-letter-q-png-image_3560577.jpg"/>
            有趣网址之家
        </span>
              <span class="link-direction">
            分享有趣的国内外网站&amp;应用新奇的html5网站
        </span>
            </a>
            <a class="link-box" href="https://www.iloveimg.com/zh-cn/" target="_blank">
        <span class="link-name">
            <img class="link-favicon" src="https://www.iloveimg.com/img/iloveimg.svg"/>
            I❤IMG️
        </span>
              <span class="link-direction">
            可批量编辑图片的所有工具,在线图片编辑器就在这里，而且永远免费！
        </span>
            </a>
          </div>  <!--class="link-box-area"-->
          <hr>
          <div style="text-align:center;">
            <span class="with-love" id="animate1">❤</span>
            评论留言互换友链 o ((&gt;ω&lt;)) o
            <span class="with-love" id="animate2">❤</span>
          </div>
          <hr>
          <div class="note success">
            <h2 id="youlian">申请友链格式</h2>
            <br>
            <p>可在下方评论区申请友链信息（网站地址、昵称、描述和头像图片url），本站信息参考如下所示:</p>
            <br>
            <ul>
              <li>名称：落迁忆の博客</li>
              <li>网址：<a href="https://www.luoqianyi.top/" data-pjax-state="">https://www.luoqianyi.top/</a></li>
              <li> 头像：<a href="https://api.luoqianyi.top/imgs/logo.png" data-pjax-state="">https://api.luoqianyi.top/imgs/logo.png</a></li>
              <li> 描述：本人的博客呀~（20字以内为最佳）</li>
            </ul>
          </div>
          <br>
        </div>
        <div class="comment">
          <comment :login="login" :username="username" :user_image="user_avatar"></comment>
        </div>
      </article>
    </div>
  </div>
</template>

<script>
import Comment from "./commons/comment";

export default {
  name: "friends",
  components: {Comment,},
  data() {
    return {
      login: false,
      username: '',
      user_avatar: '',
      friendsList:[]
    }
  },
  created() {
    this.$store.state.v = '友人帐';
    let token = localStorage.getItem('token');
    if (token === null) {
      console.log('未登录');
      this.login = false;
    } else if (token.substring(0, 6) !== 'Bearer') {
      console.log('验证失败');
    } else {
      console.log('ok');
      this.$axios({
        method: 'get',
        url: '/api/getUser',
        headers: {
          token: localStorage.getItem('token')
        }
      })
          .then(res => {
            console.log(res);
            if (res.data === '') {
              this.login = false;
              this.username = '';
              this.$message.error('token过期！请重新登陆！');
            } else {
              this.login = true;
              this.username = res.data.username;
              this.user_avatar = 'https://api.luoqianyi.top/imgs/' + res.data.avatar;
            }
          })
          .catch(err => {
            console.log(err);
          });
    }
  },
  mounted() {
    this.$axios({
      url: 'getAllFriends',
      method: 'get'
    }).then((res)=>{
      this.friendsList = res.data
    }).catch(()=>{
      console.log("error")
      this.$message.error("获取友链失败~请稍后再试")
    })
  },
  updated() {
    document.querySelector('#subtitle').innerText = "❤ 评论留言互换友链 o ((>ω<)) o ❤"
  },
}
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
  font-family: Microsoft Yahei, "微软雅黑", "Helvetica Neue", Helvetica, Hiragino Sans GB, WenQuanYi Micro Hei, sans-serif;
}

.container {
  max-width: 1100px;
  margin: 0 auto;
}

.more-title {
  text-align: center;
  font-weight: normal;
  font-size: 25px;
  margin: 20px 0 0 0;
}

/*放置链接框的区域*/
.link-box-area {
  padding-top: 25px;
  overflow: hidden;
  zoom: 1;
}

/*链接框*/
.link-box {
  width: 30%;
  display: inline-block;
  border-radius: 5px;
  background: #f5f5f5;
  height: 150px;
  margin-left: 2.5%;
  margin-bottom: 25px;
  box-shadow: 0 2px 6px 0 rgba(0,0,0,.12);
  float: left;
  text-decoration: none !important; /*这里这么处理是因为受下面的display: -webkit-box;影响，下划线又会回来*/
  overflow: hidden;
  -webkit-transition: all .2s linear; /*渐变效果*/
  transition: all .2s linear;
}

/*链接区域鼠标滑动浮起效果*/
.link-box:hover {
  z-index: 2; /*设置在顶层显示*/
  -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1); /*添加阴影*/
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  -webkit-transform: translate3d(0, -2px, 0); /*向上浮动*/
  transform: translate3d(0, -2px, 0);
}

/*链接名字*/
.link-box .link-name {
  font-size: 20px;
  color: white;
  width: 100%;
  display: inline-block;
  text-align: center;
  margin: 18px 0;
  /*超过一行的内容被自动截断并加上省略号*/
  text-overflow: -o-ellipsis-lastline; /*最后一行加省略号*/
  overflow: hidden;
  text-overflow: ellipsis; /*无法容纳的被加上省略号*/
  display: -webkit-box;
  -webkit-line-clamp: 1; /*超出三行截断*/
  -webkit-box-orient: vertical;
}

/*链接小图标*/
.link-box .link-name .link-favicon {
  display: inline-block;
  max-width: 30px;
  height: 30px;
  margin: -3px 2px 0 2px;
  vertical-align: middle;
  border: none;
}

/*链接描述*/
.link-box .link-direction {
  display: inline-block;
  padding: 0 14px;
  font-size: 15px;
  line-height: 25px;
  color: whitesmoke;
  /*超过三行的内容被自动截断并加上省略号*/
  text-overflow: -o-ellipsis-lastline; /*最后一行加省略号*/
  overflow: hidden;
  text-overflow: ellipsis; /*无法容纳的被加上省略号*/
  display: -webkit-box;
  -webkit-line-clamp: 3; /*超出三行截断*/
  -webkit-box-orient: vertical;
}

/*网页宽度大于900px,每列显示3个*/
@media screen and (min-width: 900px) {
  .link-box[data-role=.link-box-area
]:nth-child(3n) {
   clear: both;
 }
}

/*网页宽度在900px到600px之间,每列显示2个*/
@media screen and (max-width: 900px) and (min-width: 600px) {
  .link-box[data-role=.link-box-area

]:nth-child(2n) {
   clear: both;
 }

  .link-box {
    width: 40%;
    height: 150px;
    margin-left: 6.5%;
  }
}

/*网页宽度小于600px,每列显示1个*/
@media screen and (max-width: 600px) {
  .link-box {
    width: 90%;
    height: 150px;
    margin-left: 5%;
    clear: both;
  }
}
hr {
  background-image: repeating-linear-gradient(-45deg, #ddd, #ddd 4px, transparent 4px, transparent 8px);
  border: 0;
  height: 3px;
  margin: 40px 0;
}
.with-love {
  color: #f00;
  display: inline-block;
  margin: 0 5px;
}
.note.success {
  background: #eff8f0;
  border-left-color: #5cb85c;
}
.note {
  border-radius: 0.3rem;
  margin: auto 2.5%;
  padding:1.5em;
  position: relative;
  background: #f9f9f9;
  border: initial;
  border-left: 3px solid #eee;
}
#youlian{
  color: #5cb85c;
}
.note ul{
  margin-left: 4%;
}

.link-box:nth-child(n+1) {
  background: #2196f3
}
.link-box:nth-child(n+2) {
  background: #00abc0
}

.link-box:nth-child(n+3) {
  background: #f44336
}

.link-box:nth-child(n+4) {
  background: #ff9800
}

.link-box:nth-child(n+5) {
  background: #673ab7
}

.link-box:nth-child(n+6) {
  background: #8bc34a
}

.link-name {
  color: whitesmoke;
  position: relative;
  display: inline-block
}

.link-name::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: whitesmoke;
  visibility: hidden;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: .4s ease-in-out;
  transition: .4s ease-in-out
}

.link-name:hover {
  text-decoration: none
}

.link-name:active::after,
.link-name:hover::after {
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1)
}
</style>