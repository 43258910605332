<template>
  <el-dialog
      title="我来过这个角落~"
      :visible.sync="visible"
      @close="$emit('update:show',false)"
      :lock-scroll="false"
      :center="true"
      :top="'0px'"
      :show="show"
  >
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane name="login" label="登陆">
        <el-form
            :model="loginForm"
            :rules="rules"
            ref="loginForm"
            label-width="50px"
            class="demo-ruleForm"
        >
          <el-form-item prop="user" label="用户">
            <el-input
                type="text"
                placeholder="请输入手机号或者邮箱号"
                required="required"
                v-model="loginForm.user"
                prefix-icon="el-icon-user-solid"
            ></el-input>
          </el-form-item>
          <el-form-item prop="pass" label="密码">
            <el-input
                type="password"
                placeholder="请输入密码"
                prefix-icon="el-icon-lock"
                v-model="loginForm.pass"
                @keyup.enter.native="submitForm('loginForm')"
                customClass="customWidth"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button @click="handleReset">重置</el-button>
            <el-button type="primary" @click="submitForm('loginForm')">登录</el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane name="reg" label="注册">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm" label-width="100px">
          <el-form-item prop="username" label="用户名">
            <el-input type="text" placeholder="用户名" required="required" v-model="ruleForm.username" prefix-icon="el-icon-user-solid"></el-input>
          </el-form-item>
          <el-form-item prop="user" label="手机号/邮箱">
            <el-input class="phone-input" placeholder="手机号/邮箱" v-model="ruleForm.user" prefix-icon="el-icon-mobile-phone"></el-input>
          </el-form-item>
          <el-form-item prop="pass" label="密码">
            <el-input type="password" placeholder="请输入密码" v-model="ruleForm.pass" prefix-icon="el-icon-lock"></el-input>
          </el-form-item>
          <el-form-item prop="checkPass" label="重复密码">
            <el-input type="password" placeholder="请再次输入密码" v-model="ruleForm.checkPass" prefix-icon="el-icon-lock"></el-input>
          </el-form-item>
          <el-form-item label="用户头像">
            <el-upload
                ref="upfile"
                action="#"
                :auto-upload="false"
                :on-change="handleChange"
                :show-file-list="false"
                accept="image/png,image/gif,image/jpg,image/jpeg"
            >
              <img v-if="imgUrl" :src="imgUrl" width="80" height="80"/>
              <i style="border: 1px solid lightgrey;width: 80px;height: 80px;line-height: 80px" v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item class="btn-form">
            <el-button @click="handleReset">重置</el-button>
            <el-button type="primary" @click="reg('ruleForm')">注册</el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
  </el-dialog>
</template>

<script>
export default {
  name: "loginAndReg",
  props:{
    show:{
      type:Boolean,
      default:false
    }
  },
  watch:{
    show(){
      this.visible = this.show
    }
  },
  data(){
      var validatePass = (rule, value, callback) => {
        if (!value) {
          callback(new Error('请输入密码'))
        } else if (!/^[A-Za-z0-9]+$/.test(value) || value.length < 6) {
          callback(new Error('密码只能是6位以上英文或者数字'))
        } else {
          callback()
        }
      }
      var validateUser = async (rule, value, callback) => {
        if (value === '') {
          callback(new Error('手机号或者邮箱不能为空'))
        } else {
          const reg = /^1[3|4|5|7|8][0-9]\d{8}$/
          // eslint-disable-next-line no-useless-escape
          const reg2 = /^([a-z0-9_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/
          if ((reg.test(value) || reg2.test(value))) {
            if(value){
              if(this.loginForm.user!==''){
                const res = await this.$axios.post('/api/hasPhone',{
                  user:this.loginForm.user
                })
                if(res.data){
                  callback()
                }else{
                  callback(new Error('您好像还没有注册哦，请先注册~'))
                }
                console.log(res)
              }else if(this.ruleForm.user!==''){
                const res = await this.$axios.post('/api/hasPhone',{
                  user:this.ruleForm.user
                })
                if(!res.data){
                  callback()
                }else{
                  callback(new Error('此用户已经被注册！'))
                }
              }
            }
            callback()
          } else {
            callback(new Error('请输入正确的手机号或者邮箱'))
          }
        }
      }
      var validateName = async (rule,value,callback)=>{
      if(value===''){
        callback(new Error('请输入用户名'))
      }else{
        if(value){
          const res = await this.$axios.post('/api/reg/hasUser',{
            username:this.ruleForm.username
          })
          console.log(res)
          if(!res.data){
            callback()
          }else{
            return callback(new Error('用户名已经被注册'))
          }
        }
      }
    }
      var validateCheckPass = (rule,value,callback)=>{
      if(value===''){
        callback(new Error('请再次输入密码'))
      }else if(value!==this.ruleForm.pass){
        callback(new Error('两次输入密码不一致！'))
      }else{
        callback()
      }
    }
    return{
      fileList:'',
      imgUrl:'',
      loading:true,
      activeName:'login',
      loginForm:{
        user:'',
        pass:'',
      },
      ruleForm:{
        username:'',
        user:'',
        pass:'',
        checkPass:''
      },
      rules:{
        user: [{ required: true, validator: validateUser, trigger: 'blur' }],
        pass: [{ required: true, validator: validatePass, trigger: 'blur' }],
        username: [{required: true,validator:validateName,trigger:'blur'}],
        checkPass: [{required: true,validator:validateCheckPass,trigger:'blur'}]
      },
      visible:this.show,
      file:''
    }
  },
  methods:{
    handleClick(){
      // console.log(tab,event)
      this.handleReset()
    },
    handleReset () {
      this.$refs.loginForm.resetFields()
      this.$refs.ruleForm.resetFields()
      this.$refs.upfile.clearFiles()
      this.imgUrl = ''
      this.file = ''
      this.fileList = ''
    },
    submitForm(rules){
      console.log(rules)
      this.$refs.loginForm.validate(valid=>{
        if(valid){
          var formdata = new FormData();
          formdata.set('user',JSON.stringify(this.loginForm))
          let flag = this.openFullScreen('登陆中')
          this.$axios.post('/api/login',formdata).then(res=>{
            this.closeFullScreen(flag);
            console.log(res)
            if(res.data===""){
              this.$message.error("密码错误！请重新输入！");
              this.loginForm.pass = ''
            }else{
              localStorage.setItem('token',res.data)
              this.dialogVisible = false
              this.handleReset();
              this.$router.go(0)
              this.$message.success("登陆成功！欢迎您!")
            }
          }).catch(err=>{
            this.closeFullScreen(flag);
            console.log(err)
          })
        }else{
          console.log('验证不通过')
        }
      })
    },
    handleChange(file,fileList){
      this.fileList = fileList
      console.log(fileList)
      console.log(file)
      this.file = file
      //生成本地图片的url,直接src显示，删除或移动后url就失效了
      this.imgUrl = URL.createObjectURL(file.raw)
      console.log(this.imgUrl)
    },
    reg(rules){
      console.log(rules)
      this.$refs.ruleForm.validate(valid=>{
        if(valid){
          var fd =new FormData();
          fd.set('file',this.file.raw)
          fd.set('user',JSON.stringify(this.ruleForm))
          let flag = this.openFullScreen('注册中');
          // this.$axios.post('')
          this.$axios.post('/api/reg/',fd).then(res=>{
            console.log(res)
            if(res.data!=='null'){
              this.closeFullScreen(flag)
              localStorage.setItem('token',res.data)
              this.dialogVisible = false
              this.handleReset();
              this.$router.go(0)
              this.$message.success("注册成功！欢迎您!")
            }
          }).catch(err=>{
            this.closeFullScreen(flag);
            console.log(err)
            this.$message.error("注册失败！上传头像接口出错！")
          })
        }else{
          this.$message.error("上传图片文件格式错误！仅支持image/png,image/gif,image/jpg,image/jpeg！")
        }
      })

    },
  }
}
</script>

<style>
.el-loading-mask,.is-fullscreen {
  z-index: 1;
}
.el-dialog{
  background-image: url("../../assets/images/dialog-bk.png");
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 100%;
}
</style>
