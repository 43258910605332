<template>
  <div id="login">
<!--    <video preload="auto" class="me-video-player" autoplay="autoplay" loop="loop">-->
<!--          <source src="../assets/video/bg.mp4" type="video/mp4">-->
<!--      </video>-->

    <div class="me-login-box me-login-box-radius">
      <h1>后台管理登录</h1>

      <el-form ref="userForm" :model="userForm" :rules="rules">
        <el-form-item prop="account">
          <el-input placeholder="用户名" v-model="userForm.account"></el-input>
        </el-form-item>

        <el-form-item prop="password">
          <el-input placeholder="密码" type="password" v-model="userForm.password"></el-input>
        </el-form-item>

        <el-form-item size="small" class="me-login-button">
          <a :href="link" id="log"><el-button type="primary" @click.native="login('userForm')">登录</el-button></a>
        </el-form-item>
      </el-form>

      <div class="me-login-design">
        <p>Designed by
          <strong>
            <a href="/about" class="me-login-design-color">落迁忆</a>
          </strong>
        </p>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      userForm: {
        account: '',
        password: ''
      },
      link: '',
      rules: {
        account: [
          {required: true, message: '请输入用户名', trigger: 'blur'},
          {max: 10, message: '不能大于10个字符', trigger: 'blur'},
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          {max: 10, message: '不能大于10个字符', trigger: 'blur'},
          {min: 6,message: '不能小于6个字符',trigger: 'blur'}
        ]
      }
    }
  },
  methods: {
    //管理员身份验证
    login(formName) {
      let that = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(this.$store.state.userForm.account===this.userForm.account&&this.$store.state.userForm.password===this.userForm.password){
            this.link='https://admin.luoqianyi.top'
            $('#log').attr({
              target:'_blank'
            })
            this.$router.go(-1)
          }else{
            that.$message.error('用户名或密码错误！')
          }
        }
      });
    }
  }
}
</script>

<style scoped>
#login {
  min-width: 100%;
  min-height: 100%;
}
.me-video-player {
  background-color: transparent;
  width: 100%;
  height: 100%;
  object-fit: fill;
  display: block;
  position: absolute;
  left: 0;
  z-index: 0;
  top: 0;
}
.me-login-box {
  position: absolute;
  width: 300px;
  background-color: white;
  margin-top: 150px;
  margin-left: -150px;
  left: 50%;
  padding: 30px;
}
.me-login-box-radius {
  border-radius: 10px;
  box-shadow: 0px 0px 1px 1px rgba(161, 159, 159, 0.1);
}
.me-login-box h1 {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
  vertical-align: middle;
}
.me-login-design {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
}
.me-login-design-color {
  color: #5FB878 !important;
}
.me-login-button {
  text-align: center;
}
.me-login-button button {
  width: 100%;
}
</style>
