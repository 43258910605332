<template>
  <div id="app">
    <transition name="el-fade-in-linear">
      <router-view/>
    </transition>
  </div>
</template>
<!--<script type="text/javascript" src="../public/js/test.js"></script>-->
<!--<script type="text/javascript" src="../public/js/search.min.js"></script>-->
<script>
// import echarts from 'assets/js/echarts-wordcloud.min.js'
  export default {
    name:'app',
  }
</script>
<style>
@import './assets/styles/waves.css';
</style>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
<script>
  $(window).scroll(function (){
    if($(window).scrollTop()>=150){
      $('#header').addClass('fixed')
    }else{
      $('#header').removeClass('fixed')
    }
  })
</script>
