<template>
  <div>
    <!--  <h1>This is a works page</h1>-->
    <div class="container body-wrap">
      <article class="page-article fade in" itemprop="blogPage">
        <div class="post-content page-content" id="page-content" itemprop="pageContent">
          <div class="works-container" style="background-color: #fafafa">
            <div class="container">
              <div class="row">
                <div class="col-sm-10 col-sm-offset-1&#10; col-md-8 col-md-offset-2&#10; works-area">
                  <div class="works-item" v-for="(works,index) in works_list" :key="index">
                    <div class="title">
                      <h3>{{works.title}}</h3>
                      <span class="title-date text-muted">
                        {{works.date}}
                    </span>
                    </div>
                    <div class="works-item-img">
                      <img :src="works.img" onerror="this.src=''" />
                    </div>
                    <div class="works-content">
                      <div class="use text-warning">
                        <span v-for="(i,index1) in works.tags" :key="index1">{{i}}</span>
                      </div>
                      <p class="subtitle"></p>

                      <p class="text-muted">{{works.introduce}}</p>
                    </div>
                    <div class="works-area footer clearfix">
                      <a class=" btn btn-default" :href="works.link" target="_blank">Preview</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </div>
  </div>
</template>

<script>
export default {
  name: "works",
  data() {
    return {
        works_list:[
          {
            date:'2022/06/21',
            img:'http://1.116.189.219:9000/luoqianyi/image_10.png',
            introduce:'简单的一个乡村疫情防控管理系统，界面简单，代码简洁',
            link:'https://gitee.com/luoqianyi/covid-vue',
            tags:['团队协作','项目负责人'],
            title:'乡村疫情防控管理系统'
          },
          {
            date:'2022/04/21',
            img:'http://1.116.189.219:9000/luoqianyi/image_11.png',
            introduce:'利用GithubPage结合Docsify搭建的一个自己的在线知识库，平时学习到的笔记迁移到这上面来，现在还在不断迁移中~',
            link:'https://gitee.com/luoqianyi/luoqianyi.github.io',
            tags:['独立项目'],
            title:'落迁忆的知识库'
          },
          {
            date:'2022/03/10',
            img:'http://1.116.189.219:9000/luoqianyi/image_12.png',
            introduce:'Gitee提供的优秀简历模板修改而成，自己的在线简历',
            link:'https://gitee.com/luoqianyi/resume',
            tags:['独立项目'],
            title:'落迁忆的在线简历'
          },
          {
           date:'2020/11/29',
           img:'https://thumbs.dreamstime.com/b/%E9%A1%B9%E7%9B%AE%E7%AE%A1%E7%90%86%E5%9B%BE-52096233.jpg',
           introduce:'三人开发的包间管理项目',
           link:'https://gitee.com/luoqianyi/room_management',
           tags:['团队协作','负责前端'],
           title:'科研包间管理系统'
          },
        ]
    }
  },
  created() {
    this.$axios({
      method:'get',
      url:'/api/works'
    }).then(res=>{
      console.log(res)
      this.works_list = res.data
    }).catch(err=>{
      console.log(err)
    })
  },
  mounted() {
    this.$store.state.v = '作品'
  }
}
</script>

<style scoped>
* {
  /*border: thin red solid;*/
}

header .bg-header {
  height: 560px;
  background-color: #62A4FF;
}

/*头像*/
.bg-header .avatar img {
  border-radius: 50%;
  margin-top: 100px;
  max-width: 170px;
}

.bg-header h1,
.bg-header h2 {
  color: white;
}

/*姓名*/
.bg-header h1 {
  letter-spacing: 2px;
  text-shadow: 0 0 10px rgba(15, 15, 15, .3);
}

/*描述*/
.bg-header h2 {
  font-size: 18px;
  margin-top: 12px;
}

/*作品展示区*/
section .works-area {
  margin-top: 0px;
}

.works-area .works-item {
  border-radius: 2px;
  border: 1px solid #eee8d5;
  margin-bottom: 50px;
  box-shadow: 8px 5px 10px rgba(0, 0, 0, .2);
}

.works-item-img {
  margin: auto -26px;
}

.works-item-img img {
  width: 100%;
  height: 100%;
}

.works-area .works-item {
  background: white;
  padding: 5px 25px;
}

.works-area .footer {
  padding-top: 10px;
  border-top: thin #eee solid;
  color: #fff;
  background: #fff !important;
}

.works-area .footer .btn {
  padding: 7px 9px;
  border-radius: 2px;
  float: right;
  margin: 8px 0;
  text-transform: none;
  font-size: 12px;
  font-style: normal;
  background: #3F51B5;
  border: none;
  color: #fff;
}

.works-item .works-content {
  padding-top: 15px;
}

.works-item .title {
  padding-top: 20px;
}

.works-item .title h3 {
  display: inline-block;
  /*margin: 0 15px;*/
  height: 35px;
  font-size: 18px;
  font-weight: 400;
}

.works-item .title .title-date {
  float: right;
  font-size: 18px;
}

.works-item .use span {
  display: inline-block;
  font-size: 12px;
  border-radius: 3px;
  padding: 0 4px;
  border: thin solid #795548;
  margin-left: 5px;
}

.works-item .subtitle {
  margin: 0;
  font-size: 17px;
}

@media screen and (max-width: 400px) {
  .works-item .footer .use {
    font-size: 14px;
  }

  .works-item .works-item-img {
    height: 200px;
  }

  .bg-header .avatar img {
    max-width: 150px;
  }

  .bg-header h1 {
    font-size: 24px;
  }

  section .works-area {
    margin-top: 0px;
  }
}

</style>
