<template>
  <div class="about">
    <div class="container body-wrap">
      <article id="post-" class="post-article article-type-post fade in" itemprop="blogPost">
        <div class="post-card">
          <h1 class="post-card-title">关于我</h1>
          <div class="post-meta">
            <time class="post-time" title="2021-08-19 05:15:10" datetime="2021-08-19T05:15:10.993Z"
                  itemprop="datePublished">2021-08-19
            </time>

            <span id="lc_counter_container_page_pv" title="文章总阅读量" data-page-url="" data-page-title="关于我">
							<i class="icon icon-eye icon-pr"></i>
							<span id="lc_counter_value_page_pv">{{ view }}</span>
						</span>

            <span>
							<i class="icon icon-clock-o"></i>
							4 min.
						</span>
          </div>
          <div class="post-content" id="post-content" itemprop="postContent">
            <figure class="image-bubble">
              <div class="img-lightbox">
                <div class="overlay"></div>
                <img
                    src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fn.sinaimg.cn%2Fsinacn%2Fw600h421%2F20180110%2Fe43c-fyqnick2047686.jpg&refer=http%3A%2F%2Fn.sinaimg.cn&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1618823689&t=290ffc19ee5cd71a558cbe7cedb90322"
                    alt="当幸福来敲门"
                    title=""
                    referrerpolicy="no-referrer"
                />
              </div>
              <div class="image-caption">梦想还是要有的，万一实现了呢？</div>
            </figure>

            <h1 id="关于我">
              <a href="#关于我" class="headerlink" title="关于我"></a>
              关于我
            </h1>
            <p>
              谢谢你，在这世界的角落，找到我：一个大三的学生，昵称以
              <strong>落迁忆</strong>
              混迹江湖。在现实生活中，我是大家口中的
              <strong>好学生</strong>
              ，虽然如此，但我学习还是源于喜欢
              <code>print("Hello World!")</code>
              。目前，主要关注
              <code>Java</code>
              、
              <code>Git</code>
              、
              <code>Python</code>
              、
              <code>Vue</code>
              、
              <code>微服务</code>
              、
              <code>Web</code>
              等技术方向。日常行为：学习、游戏、电影等，喜欢看悲喜剧/科幻片/科普、刷B站、刷LeetCode等。
            </p>
            <p>近期规划：</p>
            <ul>
              <li>准备面试</li>
              <li>刷题</li>
              <li>复习</li>
            </ul>
            <p>更多的页面：</p>
            <ul>
              <li>
                <a href="/movies" rel="noopener">影单</a>
                /
                <a href="/music" rel="noopener">歌单</a>
                /
                <a href="/friends" rel="noopener">友人帐</a>
              </li>
            </ul>
            <p>更大的世界：</p>
            <ul>
              <li>
                <a href="https://user.qzone.qq.com/3228077562/main" target="_blank" rel="noopener">QQ</a>
                /
                <a href="https://www.jianshu.com/u/725b013bdc38" target="_blank" rel="noopener">简书</a>
                /
                <a href="https://blog.csdn.net/qq_45743005?spm=1001.2014.3001.5343" target="_blank"
                   rel="noopener">CSDN</a>
              </li>
            </ul>
            <h2 id="关于博客">
              <a href="#关于博客" class="headerlink" title="关于博客"></a>
              关于博客
            </h2>
            <p>
              人生有太多太多东西注定要失去，在你的记忆变得越来越模糊的时候，你唯一能做的就是让自己不要忘记。所以，在时光不舍昼夜流逝的时候，我依然想留下一点痕迹，让未来的自己有迹可循，人生由无数个过去“叠加”而成，即使那些人或者事都已面目全非，而我却一直真实地存在于此刻。所谓“
              <strong>人生到处知何似， 应似飞鸿踏雪泥</strong>
              ”，为了不让那些雪泥鸿爪被时光掩盖，我只能在这里写下这些文字，无论是偏理性思维的技术文章，还是偏感性思维的生活感悟，这些或深或浅的思考，至少都证明我曾真实存在过，这是我坚持写博客的初心，如果这些自言自语能让各位有所收获的话，我想我一定会非常开心、非常自豪的。
            </p>
            <!--            音乐播放器Aplayer-->
            <template>
              <aplayer :audio="audio" :lrcType="1" ref="aplayer" :autoplay="true"/>
            </template>
            <hr/>
            <div style="width: 100%;height: 300px;z-index: -1" id="chart"></div>
            <div style="width: 100%;height: 100px;z-index: -1" id="sign"></div>
          </div>
          <award></award>
          <div class="post-footer">
            <share-feb></share-feb>
          </div>
        </div>

        <div class="comment">
          <comment :login="login" :username="username" :user_image="user_avatar"></comment>
        </div>
      </article>
    </div>
  </div>
</template>
<script>
const comment = () => import("@/views/commons/comment")
const shareFeb = () => import("@/views/commons/shareFeb")
const award = () => import("@/views/commons/award")
export default {
  name: 'about',
  data() {
    return {
      paying_payee: require('../assets/images/paying_payee.png'),
      wechat_payee: require('../assets/images/wechat_payee.png'),
      flag: false,
      view: '',
      audio: {
        name: 'Way Back Home（中文版）',
        artist: '吴木蓝君',
        url: 'https://sp-sycdn.kuwo.cn/resource/n2/67/14/3107745260.mp3',
        cover: 'https://s2.loli.net/2022/07/02/dXYvAOVzLBD5CKF.png', // prettier-ignore
        lrc:
            '[00:00.000] 作词 : 吴木蓝君\n[00:02]时光停滞后\n[00:04]我在人海里搜索\n[00:07]谁也无法阻挡我\n[00:9]最终陪在你左右\n[00:12]结束旅行做的梦\n[00:14]有 心中的涌动\n[00:16]是你再次呼唤我\n[00:19]拥抱晴空\n[00:20]Way Back Home\n[00:42]就像是抽屉的意义\n[00:44]来来去去无法封闭\n[00:46]就像是风一般的你\n[00:49]此刻你在我怀里\n[00:51]就算是明天要别离\n[00:54]我却挥之不去 （huh huh huh）\n[00:59]在数不尽的旅途里\n[01:02]我突然遇见你\n[01:05]曾经空白的心\n[01:07]不自觉 向你靠近\n[01:10]阳光点燃空气\n[01:12]你出现在那里\n[01:14]唯一 的 宿命\n[01:19]时光停滞后\n[01:21]我在人海里搜索\n[01:23]谁也无法阻挡我\n[01:26]最终陪在你左右\n[01:28]结束旅行做的梦\n[01:31]有 心中的涌动\n[01:33]是你再次呼唤我\n[01:35]拥抱晴空\n[01:37]Way Back Home\n[01:57]迷失破损的记忆\n[02:00]我就像猜谜语\n[02:02]专属的点点滴滴\n[02:05]最清晰的 是你\n[02:07]迷路后的风景\n[02:09]是抓不住的心\n[02:12]还是 我的宿命？\n[02:17]时光停滞后\n[02:19]我在人海里搜索\n[02:21]谁也无法阻挡我\n[02:23]最终陪在你左右\n[02:26]结束旅行做的梦\n[02:29]有 心中的涌动\n[02:30]是你再次呼唤我\n[02:33]拥抱晴空\n[02:34]Way Back Home\n[02:36]我还是看不懂\n[02:39]你这么做\n[02:41]你给我的笑容\n[02:43]沉醉其中\n[02:46]转身无影无踪\n[02:51]留下一道彩虹\n[03:04]是你再次呼唤我\n[03:07]追逐在你身后\n[03:14]猛然醒悟了\n[03:16]站在地平线呆着\n[03:19]我慢慢抬起头\n[03:21]明白要的是什么\n[03:24]光芒开始刺痛 了我\n[03:26]驱散了朦胧\n[03:28]是你再次呼唤我\n[03:31]拥抱晴空\n[03:32]Way Back Home\n'
      },
      login: false,
      username: '',
      user_avatar: ''
    };
  },
  components: {
    award,
    shareFeb,
    comment
  },
  methods: {
    changeImg() {
      this.flag = !this.flag;
    },
    drawCharts() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById('chart'));
      myChart.setOption({
        title: {
          text: '技能点',
          left: 'center'
        },
        series: [
          {
            type: 'pie',
            data: [
              {
                value: 500,
                name: 'Java'
              },
              {
                value: 400,
                name: '大数据'
              },
              {
                value: 300,
                name: 'Python'
              },
              {
                value: 200,
                name: 'Linux'
              },
              {
                value: 100,
                name: 'Go'
              }
            ],
            radius: '55%',
            roseType: 'area'
          },
        ]
      });
    },
    drawSign() {
      let mySign = this.$echarts.init(document.getElementById('sign'))
      mySign.setOption(
          {
            graphic: {
              elements: [
                {
                  type: 'text',
                  left: 'center',
                  top: 'center',
                  style: {
                    text: '落迁忆の博客',
                    fontSize: 40,
                    fontWeight: 'bold',
                    lineDash: [0, 200],
                    lineDashOffset: 0,
                    fill: 'transparent',
                    stroke: '#392d2d',
                    lineWidth: 2
                  },
                  keyframeAnimation: {
                    duration: 3000,
                    loop: true,
                    keyframes: [
                      {
                        percent: 0.7,
                        style: {
                          fill: 'transparent',
                          lineDashOffset: 200,
                          lineDash: [200, 0]
                        }
                      },
                      {
                        // Stop for a while.
                        percent: 0.8,
                        style: {
                          fill: 'transparent'
                        }
                      },
                      {
                        percent: 1,
                        style: {
                          fill: 'transparent'
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
      );
    },
  },
  created() {
    let token = localStorage.getItem('token');
    if (token === null) {
      console.log('未登录');
      this.login = false;
    } else if (token.substring(0, 6) !== 'Bearer') {
      console.log('验证失败');
    } else {
      console.log('ok');
      this.$axios({
        method: 'get',
        url: '/api/getUser',
        headers: {
          token: localStorage.getItem('token')
        }
      })
          .then(res => {
            console.log(res);
            if (res.data === '') {
              this.login = false;
              this.username = '';
              this.$message.error('token过期！请重新登陆！');
            } else {
              this.login = true;
              this.username = res.data.username;
              this.user_avatar = 'https://api.luoqianyi.top/imgs/' + res.data.avatar;
            }
          })
          .catch(err => {
            console.log(err);
          });
    }
  },
  mounted() {
    this.$store.state.v = '关于我';
    this.drawCharts();
    this.drawSign()
    // this.$router.replace(
    //     {
    //       path:'/_empty',
    //     }
    // );
    // this.$router.replace({
    //   path:'/about',
    // })
    this.$refs.aplayer.showLrc();
    /***************图片放大全屏***********************/
    $(function () {
      $('.img-lightbox')
          .find('img')
          .click(function () {
            console.log('图片查看');
          });
    });
  },
  updated() {
    this.$axios({
      url: '/api/addViews',
      method: 'post',
      headers: {
        route: 'About_views'
      }
    }).then(res => {
      this.view = res.data;
    });
  }
};
</script>
<style scoped>
</style>
