<template>
<div>
<!--  <h1>This is a categories page</h1>-->
  <div class="tabs-bar" style="margin-top: 0;">
    <nav class="tags-list" style="margin-left: 10%">
      <router-link v-waves @click.native="getAll()" to="all" style="-webkit-order:-1;order:-1" class="tags-list-item waves-effect waves-button waves-light active" id="all">
        全部
      </router-link>
      <router-link v-waves :id="category" @click.native="getArticle(category)" :to="category" v-for="(category,index) in to" :key="index" tag="a" style="-webkit-order:-1;order:-1" class="tags-list-item waves-effect waves-button waves-light ">
        {{category}}
      </router-link>
    </nav>
    <!-- PC show more-->
    <div class="tags-list-more">
      <a href="javascript:;" @click="showMore()" onclick="BLOG.tabBar(this)" class="action tags-list-item waves-effect waves-circle waves-light"><i class="icon el-icon-more"></i></a>
    </div>
  </div>
  <div class="container body-wrap fade in">
    <div v-for="(item,index) in categoriesArticle" :key="index">
      <h3 class="archive-separator" :id="'category-'+item.category" >{{item.category}}({{item.sumArticle}})</h3>
      <div class="waterfall in" :style="'height:'+ 175*(parseInt((item.sumArticle+1)/2))+'px'" >
        <div :style="'top:'+(parseInt(id/2))*175+'px;left:'+(id%2==1)*50+'%'" class="waterfall-item" v-for="(list,id) in item.articleList" :key="id">
          <article class="article-card archive-article">
            <div class="post-meta">
              <time class="post-time" :title="list.created" :datetime="list.created"  itemprop="datePublished">{{list.created}}</time>
              <ul class="article-category-list">
                <li class="article-category-list-item">
                  <a class="article-category-list-link" :href="'/categories/'+list.category">{{list.category}}</a>
                </li>
              </ul>
            </div>
            <h3 class="post-title" itemprop="name">
              <a class="post-title-link" :href="'/post/'+list.aid">{{list.title}}</a>
            </h3>
            <div class="post-footer">

              <ul class="article-tag-list" itemprop="keywords">
                <li class="article-tag-list-item" v-for="(index,t) in list.tags" :key="index"><a @click="getArticle(list.tags[t])" class="article-tag-list-link" :href="'/tags/'+list.tags[t]" rel="tag">{{list.tags[t]}}</a></li>
              </ul>

            </div>

          </article>
        </div>
      </div>
    </div>
  </div>


</div>
</template>

<script>
export default {
  name: "categories",
  data(){
    return{
      to:[],
      categoriesArticle:[
        {
          category:'加载中',
          sumArticle:'...',
          articleList:[
          ]
        },
      ],
      categories:'all'
    }
  },
  methods:{
    showMore(){
      if($('.tabs-bar').hasClass('expand')){
        $('.tabs-bar').removeClass('expand');
      }else{
        $('.tabs-bar').addClass('expand');
      }
    },
    getArticle(category){
      $('.tags-list-item').removeClass('active')
      $('#'+category).addClass('active')
      this.$axios({
        url:'/api/categories/'+category,
        method: 'get'
      }).then(res=>{
        console.log(this.$route.params.categoryName)
        this.categoriesArticle = [{category:'',sumArticle: '',articlList: []}]
        this.categoriesArticle[0].category = category
        this.categoriesArticle[0].sumArticle = res.data.length
        this.categoriesArticle[0].articleList = res.data
      }).catch(err=>{
        console.log(err)
      })
    },
    getAll(){
      // $('.tags-list-item').css('border-bottom','none');
      this.$axios({
        url:'/api/categories/all',
        method:'get',
      }).then(res=>{

        this.to = res.data.all
        this.categoriesArticle = []
        for (const resKey in res.data) {
          if(resKey!=='all'&&resKey!=='AllArticle'){
            let len = res.data[resKey][0].length
            let categoryList = {}
            categoryList.category = resKey
            categoryList.sumArticle = len
            categoryList.articleList = res.data[resKey][0]
            if(categoryList.sumArticle>0){
              this.categoriesArticle.unshift(categoryList)
            }
          }

        }
      }).catch(err=>{
        console.log(err)
      })
    },
  },
  mounted() {
    this.$store.state.v = '分类'
    this.$axios({
      url:'/api/categories/all',
      method:'get'
    }).then(res=>{
      this.to=res.data.all
    })
    this.$axios({
      url:'/api/categories/'+this.$route.params.categoryName,
      method:'get',
    }).then(res=>{
      if(this.$route.params.categoryName==='all'){
        this.categoriesArticle = []
        for (const resKey in res.data) {
          if(resKey!=='all'&&resKey!=='AllArticle'){
            let len = res.data[resKey][0].length
            let categoryList = {}
            categoryList.category= resKey
            categoryList.sumArticle = len
            categoryList.articleList = res.data[resKey][0]
            if(categoryList.sumArticle>0){
              this.categoriesArticle.unshift(categoryList)
            }
          }
        }
      }
      else{
        this.categoriesArticle = [{tag:'',sumArticle: '',articleList: []}]
        this.categoriesArticle[0].category = this.$route.params.categoryName
        this.categoriesArticle[0].sumArticle = res.data.length
        this.categoriesArticle[0].articleList = res.data
      }
    }).catch(err=>{
      console.log(err)
      this.$router.push({ name:'page500'})
    })
  }
}
</script>

<style scoped>
</style>
