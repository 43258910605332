<template>
  <div class="not-found">
    <div class="container">
      <div class="row">
        <div  class="col-sm-5">
          <div class="ghost">
            <a href="#" class="body">
              <div style="position: absolute;width: 85px;height: 85px;bottom: 30px;">
                <i class="icon icon-flickr" style="position: absolute;"></i>
              </div>

            </a>
          </div>
        </div>

        <div class="col-sm-7">
          <h2>Internal Server Error</h2>
          <p>500<br />尝试一下返回<a style="color: red" href="/">主页</a>...</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "page500",
  mounted() {
    //创建眼球跟随效果
    $('body').mousemove(function (){
      var x = event.clientX*100/window.innerWidth+"%";
      var y = event.clientY*100/window.innerHeight+"%";
      $('.icon-flickr').css({
        left:x,
        top:y,
        transform:'translate(-x,-y)'
      })
    });

    const s = document.createElement('script');
    s.innerHTML  = 'try{Typekit.load();}catch(e){}';
    document.body.appendChild(s);
  }
}
</script>

<style scoped src="../../public/error/css/style.css"/>
<style scoped>
.not-found{
  width: 100%;
}
</style>
