<template>
  <div>
    <div style="width: 100%;height: 400px;" class="bg">
      <div style="width: 90%;">
          <meting-js :autoplay="true"
                     :fixed="false"
                     :id="7483952902"
                     :mini="false"
                     :volume="0.4"
                     server="netease"
                     type="playlist"/>
      </div>

    </div>

  </div>
</template>
<script>
export default {
  name: "music",
  data() {
    return {
    }
  },
  created() {
    this.$store.state.v = '音乐';
  },
  updated() {
    document.querySelector('#subtitle').innerText="静静听歌，此刻内心是安心的"
  },

}
</script>
<style scoped>
.bg {
  background-image: url("../assets/images/img1.gif");
  background-repeat: no-repeat;
  background-size: cover;

  display: flex;
  justify-content: center;
  align-items: center;

}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}

</style>
