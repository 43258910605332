<template>
	<div>
		<div class="container body-wrap">
			<span class="open" @click="showCatalog(flag)">
				<el-icon class="el-icon-help" style="font-size: 40px;"></el-icon>
				目录
			</span>
			<!-- <span v-else class="open" @click="showCatalog(flag)" v-waves><el-icon style="font-size: 24px" class="el-icon-close"></el-icon></span> -->
			<div class="post-widget">
				<nav  class="post-toc-wrap fixed" id="post-toc" style="border:3px solid lightgrey;height: 400px;margin-top: 45px;opacity: 100%">
					<h4 style="color: black">目录</h4>
					<ol class="post-toc"></ol>
				</nav>
			</div>

			<article :id="'post-' + title" class="post-article article-type-post fade in" itemprop="blogPost">
				<div class="post-card">
					<h1 class="post-card-title">{{ title }}</h1>
					<div class="post-meta">
						<time class="post-time" :title="time" itemprop="datePublished">{{ time }}</time>

						<ul class="article-category-list">
							<li class="article-category-list-item">
								<a class="article-category-list-link" :href="'/categories/' + category">{{ category }}</a>
							</li>
						</ul>

						<span id="lc_counter_container_page_pv" title="文章总阅读量" data-page-url="" :data-page-title="title">
							<i class="icon icon-eye icon-pr"></i>
							<span id="lc_counter_value_page_pv">{{ views }}</span>
						</span>

						<span>
							<i class="icon icon-clock-o"></i>
							{{ readTime }}min
						</span>
					</div>
					<div class="post-content" id="post-content" itemprop="postContent">
						<div class="article" id="update_article" v-html="content" v-highlight></div>
						<div class="recommended_posts">
							<h1>推荐阅读</h1>
							<ul>
								<li v-for="(item, index) in this.recommend_list" :key="index">
									<a :href="'/post/' + item.aid">{{ item.title }}</a>
								</li>
							</ul>
						</div>
					</div>

					<blockquote class="post-copyright">
						<div class="content">
							<p>
								<b>版权声明：</b>
								<a :href="'/post/' + this.$route.params.postId" rel="external">{{ title }}</a>
								，由&nbsp;
								<a href="/about" target="_blank" rel="external">落迁忆</a>
								&nbsp; 首次发表于&nbsp;
								<a href="/" target="_blank" rel="external">落迁忆的个人博客</a>
								&nbsp; ，本文链接:
								<a :href="'/post/' + this.$route.params.postId" target="_blank" rel="external">https://www.luoqianyi.top/post/{{this.$route.params.postId}}</a>
								，转载请注明
								<b>作者</b>
								和
								<b>出处</b>
								。
							</p>
						</div>
						<footer>
							<a href="/about">
								<img src="../assets/images/avatar.jpg" alt="落迁忆" />
								落迁忆
							</a>
						</footer>
					</blockquote>

					<award></award>

					<div class="post-footer">
						<div
							class="agree"
							v-waves
							@click="save_agree(agree)"
							style="cursor: pointer;background-color: rgb(234,236,247);width: 60px;height: 35px;border-radius: 15px;bottom: 40px"
						>
							<span style="font-size: 16px;color: gray;height: 35px;display: block;text-align: center;line-height: 35px;">
								<i class="icon icon-heart"></i>
								&nbsp;{{ agree }}
							</span>
						</div>
						<ul class="article-tag-list" itemprop="keywords">
							<li class="article-tag-list-item" v-for="(item, index) in tags" :key="index">
								<a class="article-tag-list-link" :href="'/tags/' + item" rel="tag">{{ item }}</a>
							</li>
						</ul>

						<share-feb :title="title"></share-feb>
					</div>
				</div>

				<nav class="post-nav flex-row flex-justify-between" style="opacity: 100%">
					<!--        上一篇文章-->
					<div class="waves-block waves-effect prev" v-waves>
						<a :href="'/post/' + prev.aid" id="post-prev" class="post-nav-link">
							<div class="tips">
								<i class="icon icon-angle-left icon-lg icon-pr"></i>
								Prev
							</div>
							<h4 class="title">{{ prev.title }}</h4>
						</a>
					</div>
					<!--下一篇文章-->
					<div class="waves-block waves-effect next" v-waves>
						<a :href="'/post/' + next.aid" id="post-next" class="post-nav-link">
							<div class="tips">
								Next
								<i class="icon icon-angle-right icon-lg icon-pl"></i>
							</div>
							<h4 class="title">{{ next.title }}</h4>
						</a>
					</div>
				</nav>

				<div id="comment"><comment :user_image="user_avatar" :login="login" :username="username"></comment></div>
			</article>
		</div>
	</div>
</template>

<script>
const comment = () => import('@/views/commons/comment');
const shareFeb = () => import('@/views/commons/shareFeb');
const award = () => import('@/views/commons/award');

export default {
	name: 'post',
	components: {
		comment,
		award,
		shareFeb
	},
	data() {
		return {
			login: false,
			username: '',
			user_avatar: '',
			title: '加载中...',
			category: '',
			time: '',
			views: '',
			readTime: '',
			tags: [],
			content: '加载中...',
			flag: true,
			agree: 0,
			agree_status: 'no',
			prev: {
				aid: '',
				title: ''
			},
			next: {
				aid: '',
				title: ''
			},
			recommend_list: [
				{
					aid: '',
					title: ''
				}
			]
		};
	},
	methods: {
		save_agree(agree) {
			if (localStorage.getItem(this.$route.fullPath) != null) {
				this.agree_status = localStorage.getItem(this.$route.fullPath);
			}
			if (this.agree_status === 'no') {
				this.agree_status = 'yes';
				this.agree = agree + 1;
				$('.agree').css({ backgroundColor: 'rgb(255,248,248)' });
				$('.agree>span').css({ color: 'rgb(248,105,96)' });
			} else if (this.agree_status === 'yes') {
				this.agree_status = 'no';
				this.agree = agree - 1;
				$('.agree').css({ backgroundColor: 'rgb(234,236,247)' });
				$('.agree>span').css({ color: 'gray' });
			}
			localStorage.setItem(this.$route.fullPath, this.agree_status);
			this.$axios({
				url: '/api/post/agree/' + this.$route.params.postId + '/' + this.agree,
				method: 'put'
			})
				.then(res => {
					if (res.data !== 1) {
						this.$notify({
							title: '点赞失败！',
							message: '服务器出错！',
							type: 'error'
						});
					}
				})
				.catch(err => {
					console.log(err);
				});
		},
		jump(idName) {
			document.querySelector(idName).scrollIntoView(true);
			// var scorlledY = window.scrollY;
			// if(scorlledY){
			//   window.scroll(0,scorlledY-80)
			// }
		},
		showCatalog(flag) {
			flag = !this.flag;
			this.flag = flag;
			if (!flag) {
				$('.open').css({
					zIndex: 9999,
					right: '71%'
				});
				$('.post-widget').css({
					display: 'block',
					width: '80%'
				});
				$('#post-toc').css({
					zIndex: 9999,
					width: '80%',
					opacity: '100%'
				});
			} else {
				$('.open').css({ right: '0px' });
				$('.post-widget').css({ display: 'none' });
			}
		}
	},
	created() {
		let token = localStorage.getItem('token');
		if (token === null) {
			console.log('未登录');
			this.login = false;
		} else if (token.substring(0, 6) !== 'Bearer') {
			console.log('验证失败');
		} else {
			console.log('ok');
			this.$axios({
				method: 'get',
				url: '/api/getUser',
				headers: { token: localStorage.getItem('token') }
			})
				.then(res => {
					console.log(res);
					if (res.data === '') {
						this.login = false;
						this.username = '';
						this.$message.error('token过期！请重新登陆！');
					} else {
						this.login = true;
						this.username = res.data.username;
						this.user_avatar = 'https://api.luoqianyi.top/imgs/' + res.data.avatar;
					}
				})
				.catch(err => {
					console.log(err);
				});
		}
		// var flag = ''
		this.$axios({
			method: 'get',
			url: '/api/post/' + this.$route.params.postId,
			headers: { token: localStorage.getItem('token') }
		})
			.then(res => {
				console.log(res);
				this.title = res.data.title;
				this.content = res.data.content;
				console.log(this.title);
				this.$store.commit('setV', res.data.title);
				console.log(this.$store.getters.getV);
				this.time = this.getNowFormatDate(res.data.created, 'Y-M-D');
				this.category = res.data.category;
				this.views = res.data.views;
				this.readTime = res.data.readTime;
				this.tags = res.data.tags;
				this.agree = res.data.agree;
			})
			.catch(err => {
				console.log(err);
			});
	},
	mounted() {
		var _this = this;
		this.$axios({
			method: 'get',
			url: '/api/index'
		}).then(res => {
			console.log(res.data);
			var article_list = res.data;
			var recomend_list = [];
			var i = 0;
			var post_id = this.$route.params.postId;
			// console.log(post_id)
			var _this = this;
			article_list.forEach(function(item, index) {
				if (item.recommed === 1) {
					recomend_list.push(item);
				}
				console.log(item.aid)
				if (item.aid == post_id) {
          console.log('ok!')
					$('h4.title').css({ visibility: 'visible' });
					i = index;
					if (i === 0) {
						_this.next = {
							aid: article_list[i + 1].aid,
							title: article_list[i + 1].title
						};
						$('.prev').css({ visibility: 'hidden' });
					} else if (i === article_list.length - 1) {
						_this.prev = {
							aid: article_list[i - 1].aid,
							title: article_list[i - 1].title
						};
						$('.next').css({ visibility: 'hidden' });
					} else {
						_this.prev = {
							aid: article_list[i - 1].aid,
							title: article_list[i - 1].title
						};
						_this.next = {
							aid: article_list[i + 1].aid,
							title: article_list[i + 1].title
						};
						console.log(_this.prev);
					}
				}
				// console.log(index+','+item.recommed)
			});
			this.recommend_list = this.getRandomArrayElements(recomend_list, 3);
			console.log(this.recommend_list);
		});
		if ($(window).width() >= 760) {
			$('.title,.open').css('visibility', 'hidden');
		} else {
			$('.title,.open').css('visibility', 'visible');
      //点击空白区域隐藏弹出框(全局)
      $(document).click(function(e) {
        // 目标区域，点击某个弹出的区域
        var pop = $('nav,.open');
        _this.flag = true;
        if (!pop.is(e.target) && pop.has(e.target).length === 0) {
          //点击外围区域隐藏弹出框
          $('.open').css({ right: '0px' });
          $('.post-widget').css({ display: 'none' });
        }
      });
		}
		$(window).scroll(function() {
			$('.post-toc-item').removeClass('active');
		});
	},
	beforeUpdate() {
		$('#menu').addClass('hide');
	},
	updated() {
		//获取浏览量
		this.$axios({
			method: 'post',
			url: '/api/addViews',
			headers: { route: this.$route.path }
		})
			.then(res => {
				this.views = res.data;
			})
			.catch(err => {
				console.log(err);
			});
		var article_titleList = [];
		$('#update_article')
			.find('h1,h2,h3,h4,h5,h6')
			.each(function(index, item) {
				var tag = $(item).get(0).localName;
				var text = $(item).get(0).innerText;
				var id = $(item)
					.children('a')
					.attr('id');
				article_titleList.push({
					tag: tag,
					text: text,
					id: id
				});
			});
		var post_toc_len = $('.post-toc').children().length;
		if (post_toc_len === 0) {
			article_titleList.forEach(function(item) {
				$('.post-toc').append(
					'<li class="post-toc-item level-' +
						item.tag +
						'" style="cursor: pointer"><a class="post-toc-link" link="' +
						item.id +
						'"><span class="post-toc-number"></span><span class="post-toc-text">' +
						item.text +
						'</span></a></li>'
				);
				$('.level-h1').css('margin-left', 0);
				$('.level-h2').css('margin-left', 10);
				$('.level-h3').css('margin-left', 20);
				$('.level-h4').css('margin-left', 30);
				$('.level-h5').css('margin-left', 40);
				$('.level-h6').css('margin-left', 50);
			});
		}
		$(document).on('click', '.post-toc-item', function() {
			$('.post-toc-item').removeClass('active');
			var id_selector = $(this)
				.find('.post-toc-link')
				.attr('link');
			// // alert(id_selector)
			$('html,body').animate({ scrollTop: $('#' + id_selector).offset().top - 60 }, 100);
			var _this = $(this);
			setTimeout(function() {
				_this.addClass('active');
			}, 150);
			// document.getElementById(id_selector).scrollIntoView(true)
		});
		$('.hljs').css({
			overflow: 'scroll',
			backgroundColor: 'rgb(246,250,254)'
		});
		if (localStorage.getItem(this.$route.fullPath) == 'yes') {
			$('.agree').css({ backgroundColor: 'rgb(255,248,248)' });
			$('.agree>span').css({ color: 'rgb(248,105,96)' });
		} else if (localStorage.getItem(this.$route.fullPath) == 'no') {
			$('.agree').css({ backgroundColor: 'rgb(234,236,247)' });
			$('.agree>span').css({ color: 'gray' });
		}
	}
};
</script>

<style scoped>
nav ol > li {
	color: black;
}

nav {
	background-image: url('../assets/images/dialog-bk.png');
	opacity: 60%;
	border-radius: 15px;
}

.open {
	position: fixed;
	z-index: 999;
	right: 0;
	top: 50%;
	cursor: pointer;
	width: 15px;
	color: lightgray;
}
</style>
