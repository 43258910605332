<template>
	<div class="index">
		<div class="container body-wrap">
			<ul class="post-list">
				<li class="post-list-item fade in" v-for="(item, index) in nowPageArticle" :key="index">
					<article :id="'post-' + item.id" class="article-card article-type-post" itemprop="blogPost">
						<div class="post-meta">
							<time class="post-time" :title="item.post_time" :datetime="item.post_time" itemprop="datePublished">{{ item.post_time }}</time>

							<ul class="article-category-list">
								<li class="article-category-list-item">
									<a class="article-category-list-link" :href="'/categories/' + item.catagories">{{ item.catagories }}</a>
								</li>
							</ul>
						</div>

						<h3 class="post-title" itemprop="name">
							<a class="post-title-link" :href="'/post/' + item.aid">{{ item.title }}</a>
						</h3>

						<div class="post-content" id="post-content" itemprop="postContent">
							{{ item.discribe }}
							<a :href="'/post/' + item.aid" class="post-more waves-effect waves-button">阅读全文…</a>
						</div>

						<div class="post-footer">
							<ul class="article-tag-list" itemprop="keywords">
								<li class="article-tag-list-item" :key="index1" v-for="(item1, index1) in item.tags">
									<a class="article-tag-list-link" :href="'/tags/' + item1" rel="tag">{{ item1 }}</a>
								</li>
							</ul>
						</div>
					</article>
				</li>
			</ul>
			<nav id="page-nav">
				<pageination
					:total-data="this.article.length"
					@pageBar="handleChangePage"
					:layout="'total,prev,pager,next'"
					:background="true"
					:prev_text="'<'"
					:next_text="'>'"
          small
					:pagesize="one_pageSize"
				></pageination>
			</nav>
		</div>
	</div>
</template>

<script>
const pageination = () => import('@/views/commons/pageination');
export default {
	name: 'index',
	components: {
		pageination
	},
	data() {
		return {
			article: [
				{
					title: '加载中...',
					id: '加载中...',
					post_time: '...',
					discribe: '',
					catagories: '',
					tags: []
				}
			],
			currentPage: 1,
			nowPageArticle: [
				{
					title: '加载中...',
					id: '',
					post_time: '加载中...',
					discribe: '加载中...',
					catagories: '加载中...',
					tags: []
				}
			],
			page_count: 0,
			one_pageSize: 5
		};
	},
	// beforeUpdate() {
	//
	//   //生命周期函数，在即将渲染数据到界面前进行数据的处理
	//   this.eachPage[this.currentPage-1] = this.article.slice(2*(this.currentPage-1),2*this.currentPage)
	// },
	created() {
		this.$store.state.v = '主页';
		this.$axios({
			method: 'get',
			url: '/api/'
		})
			.then(resp => {
				console.log(resp.data);
				this.article = resp.data;
				this.nowPageArticle = this.article.slice(0, this.one_pageSize);
				for (let i = 0; i < this.article.slice(0, this.one_pageSize).length; i++) {
					this.nowPageArticle[i].post_time = this.article.slice(0, this.one_pageSize)[i].created;
					this.nowPageArticle[i].id = this.article.slice(0, this.one_pageSize)[i].aid;
					this.nowPageArticle[i].discribe = this.article.slice(0, this.one_pageSize)[i].discribe.substring(0, 100);
					this.nowPageArticle[i].catagories = this.article.slice(0, this.one_pageSize)[i].category;
				}
			})
			.catch(err => {
				console.log(err);
			});
	},
	methods: {
		// select_page(page){
		//   /*
		//     分页组件，选择哪一页，页面展示哪一页的数据
		//   */
		//   this.currentPage = page
		// }
		handleChangePage(val) {
			this.$axios({
				url: '/api/index',
				method: 'post',
				data: val
			})
				.then(res => {
					console.log(res);
					let datas = res.data;
					this.nowPageArticle = res.data;
					for (let i = 0; i < datas.length; i++) {
						this.nowPageArticle[i].post_time = datas[i].created;
						this.nowPageArticle[i].id = datas[i].aid;
						this.nowPageArticle[i].discribe = datas[i].discribe.substring(0, 100);
						this.nowPageArticle[i].catagories = datas[i].category;
					}
				})
				.catch(err => {
					console.log(err);
					if (err.status === 500) {
						this.$router.replace('/500');
					}
				});
			console.log(val);
		}
	}
};
</script>

<style scoped>
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
	background-color: rgb(63, 81, 181);
	color: #fff;
}
</style>
