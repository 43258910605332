import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import about from "../views/about";
import index from "../views/index";
import archives from "../views/archives";
import categories from "../views/categories";
import tags from "../views/tags";
import movies from "../views/movies";
import works from "../views/works";
import page404 from "../views/Page404"
import page500 from "../views/Page500"
import Login from "@/views/Login";
import music from "@/views/music"
import post from "@/views/post";
import friends from "../views/friends";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect:'/index',
    children:[
      {
        path:'/about/',
        component:about,
        name: 'about'
      },
      {
        path:'/index',
        component: index,
        name:'index'
      },
      {
        path:'/categories/:categoryName',
        component: categories,
          name: 'categories'
      },
      {
        path:'/tags/:tagName',
        component: tags,
        name:'tags',
      },
      {
        path:'/works/',
        component: works,
        name:'works'
      },
      {
        path:'/archives/',
        component: archives,
        name:'archives'
      },
      {
        path:'/movies/',
        component: movies,
        name:'movies'
      },
      {
        path: '/post/:postId',
        component: post,
        name: 'post'
      },
      {
        path: '/music/',
        component: music,
        name: 'music'
      },
      {
        path: '/friends',
        component: friends,
        name: 'friends'
      }
    ]
  },
  {
    path:'*',
    component: page404,
    name:'page404'
  },
  {
    path: '*',
    component: page500,
    name: 'page500'
  },
  {
    path: '/login',
    component: Login,
    name: 'Login'
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/about.vue')
  // }
]

const router = new VueRouter({
  routes,
  mode: 'history',
  base: process.env.BASE_URL,  //当不好用时候，可以加上这句话再试试
})

export default router
