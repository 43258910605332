<template>
<!--评论组件-->
  <div id="comment" style="margin-top: 5%">
    <el-form id="main_post" class="comment-part">
      <div v-if="login">
        <el-avatar :src="user_image"></el-avatar>
        <span style="color: #3f51b5;font-size: 30px">{{username}}</span>
      </div>
      <div v-else>
        <el-avatar :src="require('../../assets/images/default.jpg')"></el-avatar>
        <span style="color: grey">当前还未登陆，请先登陆后评论哦~</span>
      </div>
      <el-input
          type="textarea"
          placeholder="说点什么呢......"
          :rows="5"
          v-model="textarea"
          maxlength="500"
          show-word-limit
      ></el-input>
      <!--            添加表情模块-->
      <el-button plain @click="show=!show">添加emoji~</el-button>
      <transition name="el-zoom-in-top">
        <div v-show="show" class="el-row" style="border: 1px solid rgb(72,162,255);border-radius: 5px;background-color: white">
          <ul class="emoji-item" style="height: 120px;overflow-y: scroll;list-style: none;padding-left: 10px">
            <li class="emoji" style="float: left;width: 30px;height: 30px;margin: 5px;" v-for="(item,index) in emojiList" :key="index" @click="chooseEmoji(item.title)">
              <img :src="require('../../assets/images/qq_emoji/'+item.url)" alt="" :title="item.title"/>
            </li>
          </ul>
        </div>
      </transition>
      <el-button v-if="login" type="primary" @click="sendMsg"  style="width: 100%;margin-top: 5%">
        {{sendTip[0]}}
      </el-button>
      <el-button v-else type="info" @click.prevent="regAndLogin"  style="width: 100%;margin-top: 5%">
        {{sendTip[1]}}
      </el-button>
    </el-form>
    <div class="vcount" style="display: block">
      <strong class="vnum">{{count}} 评论</strong>
    </div>
<!--    评论回复详情-->
    <div class="vcard el-row">
      <ol class="comment_list">
          <li class="comment" :id="'comments-'+item.id" v-for="(item,index) in commentList" :key="index">
            <div :id="'div-comment-'+item.id" class="comment-body">
              <!--父评论模块-->
              <div class="comment-author">
                <el-avatar :src="item.fromAvatar"/>
                <cite class="fn">
                  <a :class="'url'" :id="'user-'+item.fromId">{{item.fromName}}</a>
                </cite>
              </div>
              <!--进行回复-->
              <div class="comment-reply">
                <a :title="item.createTime">{{item.createTime}}</a>
                <a class="reply-link" @click="createCommentDiv">回复</a>
                <el-popconfirm
                    confirmButtonText='确定删'
                    cancelButtonText='不用了'
                    icon="el-icon-info"
                    iconColor="brown"
                    title="如果删除，后面的评论都会消失哦！"
                    @confirm="deleteComment(item.id,item.createTime,0)"
                >
                  <template #reference>
                    <a class="delete-link" v-show="username===item.fromName"><i class="el-icon-delete"></i></a>
                  </template>
                </el-popconfirm>
              </div>
              <div class="comment-text">
                <pre v-html="item.content" style="white-space: pre-wrap;word-wrap: break-word;color: grey"></pre>
              </div>
              <ul class="children">
                <li class="comment" :id="'comments-'+it.commentId" v-for="(it,id) in item.listReply" :key="id">
                  <div :id="'div-comment-'+it.commentId" class="comment-body">
                    <!--子评论模块-->
                    <div class="comment-author">
                      <el-avatar :src="it.fromAvatar"/>
                      <cite class="fn">
                        <a class="url" :id="'user-'+it.fromId">{{it.fromName}}</a>
                      </cite>
                    </div>
                    <!--进行回复-->
                    <div class="comment-reply">
                      <a @click="anchorPoint('#comments-'+it.commentId,$event)" :title="it.createTime">{{it.createTime}}</a>
                      <a class="reply-link" @click="createCommentDiv($event)">回复</a>
                      <el-popconfirm
                          confirmButtonText='确定'
                          cancelButtonText='放弃'
                          icon="el-icon-info"
                          iconColor="brown"
                          title="确认删除您的此条评论么？"
                          @confirm = "deleteComment(it.commentId,it.createTime,1)"
                      >
                        <template #reference>
                          <a class="delete-link" v-show="username===it.fromName" ><i class="el-icon-delete"></i></a>
                        </template>
                      </el-popconfirm>
                    </div>
                    <div class="comment-text" id="reply">
                      <a @click="anchorPoint('#user-'+it.toId,$event)">@{{it.toName}}</a>
                      <pre v-html="it.content" style="white-space: pre-wrap;word-wrap: break-word;color: grey"></pre>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </li>
      </ol>

    </div>
    <login-and-reg :show.sync="dialogVisible"></login-and-reg>
  </div>
</template>

<script>
import loginAndReg from "@/views/commons/loginAndReg";
export default {
  name: "comment",
  components:{
    loginAndReg
  },
  props:{
    login:{
      type:Boolean,
      default:false
    },
    username:{
      type:String,
      default:'小落^.^'
    },
    user_image:{
      default:require("../../assets/images/default.jpg")
    }
  },
  data(){
      return {
        dialogVisible: false,
        textarea:'',
        sendTip:['发射~','请先登陆后评论，点击登陆~'],
        emojiList:[//表情包和路径
          {title: '赞',url:'76@2x.gif'},
          {title: '握手',url:'78@2x.gif'},
          {title: '胜利',url:'79@2x.gif'},
          {title: '抱拳',url:'118@2x.gif'},
          {title: '拳头',url:'120@2x.gif'},
          {title: '爱你',url:'122@2x.gif'},
          {title: 'OK',url:'124@2x.gif'},
          {title: '发抖',url:'41@2x.gif'},
          {title: '跳跳',url:'43@2x.gif'},
          {title: '转圈',url:'125@2x.gif'},
          {title: '炸弹',url:'55@2x.gif'},
          {title: '刀',url:'56@2x.gif'},
          {title: '咖啡',url:'60@2x.gif'},
          {title: '药',url:'62@2x.gif'},
          {title: '玫瑰',url:'63@2x.gif'},
          {title: '爱心',url:'66@2x.gif'},
          {title: '礼物',url:'69@2x.gif'},
          {title: '太阳',url:'74@2x.gif'},
          {title: '月亮',url:'75@2x.gif'},
          {title: '祝贺',url:'144@2x.gif'},
          {title: '羊驼',url:'185@2x.gif'},
          {title: '幽灵',url:'187@2x.gif'},
          {title: '点赞',url:'201@2x.gif'},
          {title: '花痴',url:'207@2x.gif'},
          {title: '啵啵',url:'214@2x.gif'},
          {title: '加油必胜',url:'245@2x.gif'},
          {title: '搬砖中',url:'260@2x.gif'},
          {title: '忙到飞起',url:'261@2x.gif'},
          {title: '捂脸',url:'264@2x.gif'},
          {title: '头秃',url:'267@2x.gif'},
          {title: '暗中观察',url:'269@2x.gif'},
          {title: '思考',url:'280@2x.gif'},
          {title: '打call',url:'282.gif'},
          {title: '睁眼',url:'0703dceefe.gif'},
          {title: '摸鱼',url:'14107dc78b.gif'},
          {title: '敬礼',url:'ce809ca9d6.gif'},
          {title: '打脸',url:'175df36b0a.gif'},
          {title: '斜眼笑',url:'178@2x.gif'},
          {title: '笑哭',url:'182@2x.gif'},
          {title: '我最美',url:'183@2x.gif'},
          {title: '比心',url:'79e4d865db.gif'},
          {title:'傲慢',url:'2016am.gif'},
          {title:'爆筋',url:'2016baojin.gif'},
          {title:'便便',url:'2016bb.gif'},
          {title:'鄙视',url:'2016bs.gif'},
          {title:'闭嘴',url:'2016bz.gif'},
          {title:'擦汗',url:'2016ch.gif'},
          {title:'悠闲',url:'2016db.gif'},
          {title:'奋斗',url:'2016fendou.gif'},
          {title:'尴尬',url:'2016gg.gif'},
          {title:'鼓掌',url:'2016gz.gif'},
          {title:'惊恐',url:'2016jk.gif'},
          {title:'可爱',url:'2016ka.gif'},
          {title:'抠鼻',url:'2016kb.gif'},
          {title:'快哭了',url:'2016kel.gif'},
          {title:'可怜',url:'2016kk.gif'},
          {title:'酷',url:'2016kuk.gif'},
          {title:'困',url:'2016kun.gif'},
          {title:'冷汗',url:'2016lengh.gif'},
          {title:'流汗',url:'2016lh.gif'},
          {title:'流泪',url:'2016ll.gif'},
          {title:'糗大了',url:'2016qd.gif'},
          {title:'敲打',url:'2016qiao.gif'},
          {title:'亲亲',url:'2016qq.gif'},
          {title:'衰',url:'2016shuai.gif'},
          {title:'调皮',url:'2016tp.gif'},
          {title:'吐',url:'2016tuu.gif'},
          {title:'偷笑',url:'2016tx.gif'},
          {title:'委屈',url:'2016wq.gif'},
          {title:'吓',url:'2016xia.gif'},
          {title:'右哼哼',url:'2016yhh.gif'},
          {title:'晕',url:'2016yun.gif'},
          {title:'折磨',url:'2016zhem.gif'},
          {title:'左哼哼',url:'2016zhh.gif'},
          {title:'再见',url:'2016zj.gif'},
          {title:'抓狂',url:'2016zk.gif'},
          {title:'哈欠',url:'icon_arrow.gif'},
          {title:'色',url:'icon_biggrin.gif'},
          {title:'撇嘴',url:'icon_confused.gif'},
          {title:'得意',url:'icon_cool.gif'},
          {title:'大哭',url:'icon_cry.gif'},
          {title:'发呆',url:'icon_eek.gif'},
          {title:'咒骂',url:'icon_evil.gif'},
          {title:'嘘',url:'icon_exclaim.gif'},
          {title:'想法',url:'icon_idea.gif'},
          {title:'憨笑',url:'icon_lol.gif'},
          {title:'发怒',url:'icon_mad.gif'},
          {title:'龇牙',url:'icon_mrgreen.gif'},
          {title:'睡',url:'icon_neutral.gif'},
          {title:'疑问',url:'icon_question.gif'},
          {title:'饥饿',url:'icon_razz.gif'},
          {title:'害羞',url:'icon_redface.gif'},
          {title:'白眼',url:'icon_rolleyes.gif'},
          {title:'难过',url:'icon_sad.gif'},
          {title:'微笑',url:'icon_smile.gif'},
          {title:'惊讶',url:'icon_surprised.gif'},
          {title:'阴险',url:'icon_twisted.gif'},
          {title:'坏笑',url:'icon_wink.gif'},
        ],
        show:false,
        count:0,
        loading:true,
        commentList:[
          {
            content:'',
            createTime:'',
            fromAvatar:'',
            fromId:'',
            fromName:'',
            id:'',
            listReply:[{}]
          },
        ]
      }
    },
  methods: {
    anchorPoint(idName, event) {
      var el = event.currentTarget;
      let ancient = el.parentElement.parentElement.parentElement.parentElement.parentElement
      ancient.querySelector(idName).scrollIntoView(true);
      // document.documentElement.scrollTop=($('#'+ancient.id).find(idName.substring(0)).offset().top-80)
      var scorlledY = window.scrollY;
      if (scorlledY) {
        window.scroll(0, scorlledY - 80)
      }
    },
    analyzeEmoji(content) {
      var pattern1 = /\[[\u4e00-\u9fa5]+\]/g;
      var pattern2 = /\[[\u4e00-\u9fa5]+\]/;
      var cont = content.match(pattern1);
      // console.log(cont)
      var str = content;
      if (cont != null) {
        for (let i = 0; i < cont.length; i++) {
          for (let j = 0; j < this.emojiList.length; j++) {
            if ("[" + this.emojiList[j].title + "]" == cont[i]) {
              var src = this.emojiList[j].url;
              var select_emoji = require('../../assets/images/qq_emoji/' + src)
              break;
            }
          }
          str = str.replace(pattern2, `<img style="width: 30px;height: 30px" src="` + select_emoji + `"/>`)
        }
      }
      // console.log(str)
      return str;
    },
    sendMsg() {
      if (this.textarea === '') {
        this.$notify.error("评论不可以为空，请重新输入~")
        return;
      }
      var whichFatherComment = 0;
      var now = $('.children').length
      for (let i = 0; i < now; i++) {
        var flag = $('.comment-part').eq(0).isChildAndSelfOf($('.children').eq(i))
        if (flag) {
          whichFatherComment = i
          break;
        }
      }
      console.log(whichFatherComment)
      let route_params = this.$route.params.postId
      if(route_params!==undefined){
        route_params = ':'+route_params
        console.log(route_params)
      }else{
        route_params=''
      }
      const now_time = this.getNowFormatDate(new Date());
      console.log(now_time)
      // var temp = `<a class="cancel" style="position: relative;right: 0;cursor: pointer">取消回复 <strong><em style="color: black"></em></strong></a>`;
      //通过用户名向后端传入这些值到数据库
      /*********前端将用户发送的数据给后端***********/


      /************************后端存储用户数据成功后前端进行数据渲染的处理************/
      console.log(document.getElementsByClassName('cancel')[0])

      if (document.getElementsByClassName('cancel')[0] !== undefined) {
        //如果回复其它用户评论
        let ing = this.openFullScreen('回复评论中')

        var comment_to = document.getElementsByClassName('cancel')[0].lastElementChild.innerText;
        comment_to = comment_to.substring(1)
        var comment_id = $('.cancel').eq(0).prev().attr('id').substring(12);
        console.log(comment_id)
        this.$axios({
          url: '/api/insertReplyComments/' + this.$route.name+route_params,
          method: 'post',
          headers: {
            token: localStorage.getItem('token'),
          },
          data: {
            commentId: comment_id,
            toName: comment_to,
            fromName: this.username,
            content: this.textarea
          }
        }).then(res => {
          console.log(res)
          var flag = 0;
          for (let comment of res.data) {
            comment.fromAvatar = "https://api.luoqianyi.top/imgs/" + comment.fromAvatar
            comment.createTime = this.getNowFormatDate(comment.createTime)
            comment.content = this.analyzeEmoji(comment.content)
            if (comment.listReply !== null) {
              for (let reply_comment of comment.listReply) {
                reply_comment.fromAvatar = "https://api.luoqianyi.top/imgs/" + reply_comment.fromAvatar
                reply_comment.createTime = this.getNowFormatDate(reply_comment.createTime)
                reply_comment.content = this.analyzeEmoji(reply_comment.content)
                flag++;
              }
            }
          }
          this.commentList = res.data
          this.count = this.commentList.length + flag
          this.$forceUpdate()
          this.closeFullScreen(ing)
        }).catch(err => {
          console.log(err)
          this.closeFullScreen(ing)
        })
      } else {
        let ing = this.openFullScreen('评论中')
        //直接回复
        this.$axios({
          method: 'post',
          url: '/api/insertFatherComments/' + this.$route.name+route_params,
          headers: {
            token: localStorage.getItem('token'),
          },
          data: {
            type: 2,
            from_name: this.username,
            content: this.textarea
          }
        }).then(res => {
          console.log(res)
          var flag = 0;
          for (let comment of res.data) {
            comment.fromAvatar = "https://api.luoqianyi.top/imgs/" + comment.fromAvatar
            comment.createTime = this.getNowFormatDate(comment.createTime)
            comment.content = this.analyzeEmoji(comment.content)
            if (comment.listReply !== null) {
              for (let reply_comment of comment.listReply) {
                reply_comment.fromAvatar = "https://api.luoqianyi.top/imgs/" + reply_comment.fromAvatar
                reply_comment.createTime = this.getNowFormatDate(reply_comment.createTime)
                reply_comment.content = this.analyzeEmoji(reply_comment.content)
                flag++;
              }
            }
          }
          this.commentList = res.data
          this.count = this.commentList.length + flag
          this.$forceUpdate()
          this.closeFullScreen(ing)
        }).catch(err => {
          console.log(err)
          this.closeFullScreen(ing)
        })
      }


      /**********************************/
      /*******************/
      this.textarea = ''
      $('.comment-part').insertBefore('#comment')
      $('.cancel').remove()
      this.count++;
    },
    isUser() {
      console.log("请先登陆")
      this.$router.replace('/')
    },
    chooseEmoji(title) {
      this.textarea += '[' + title + ']';
    },
    createCommentDiv(event) {
      $('.cancel').css('display', 'none')
      var comment = document.getElementsByClassName('comment-part')[0]
      var target = event.currentTarget.parentElement.parentElement.parentElement
      // console.log(target)
      var user_answer = event.currentTarget.parentElement.parentElement.firstElementChild.innerText
      target.insertAdjacentHTML('beforeend', '<a class="cancel" style="position: relative;right: 0;cursor: pointer">取消回复 <strong><em style="color: black">@' + user_answer + '</em></strong></a>')
      target.appendChild(comment)
      $('.cancel')[0].scrollIntoView(true)
      window.scroll(0, window.scrollY - 150)
    },
    regAndLogin() {
      this.dialogVisible = true
    },
    deleteComment(id, createTime, type) {
      //删除用户评论，后端请求,如果type是0,说明删除的是父级评论，type是1,说明是子级评论
      let route_params = this.$route.params.postId
      if(route_params!==undefined){
        route_params = ':'+route_params
        console.log(route_params)
      }else{
        route_params=''
      }
      let ing = this.openFullScreen('正在删除');
      let create_time = this.getNowFormatDate(createTime, 'Y-M-D h:m:s')
      let commentId = (type === 1) ? id : 'null'
      id = (type === 0) ? id : 'null'
      this.$axios({
        method: 'post',
        url: '/api/deleteComments/' + this.$route.name+route_params,
        headers: {
          token: localStorage.getItem('token'),
          createTime: create_time,
          commentId: commentId,
          id: id
        }
      }).then(res => {
        console.log(res)
        var flag = 0;
        for (let comment of res.data) {
          comment.fromAvatar = "https://api.luoqianyi.top/imgs/" + comment.fromAvatar
          comment.createTime = this.getNowFormatDate(comment.createTime)
          comment.content = this.analyzeEmoji(comment.content)
          if (comment.listReply !== null) {
            for (let reply_comment of comment.listReply) {
              reply_comment.fromAvatar = "https://api.luoqianyi.top/imgs/" + reply_comment.fromAvatar
              reply_comment.createTime = this.getNowFormatDate(reply_comment.createTime)
              reply_comment.content = this.analyzeEmoji(reply_comment.content)
              flag++;
            }
          }
        }
        this.commentList = res.data
        this.count = this.commentList.length + flag
        this.$forceUpdate()
        this.closeFullScreen(ing)
      }).catch(err => {
        console.log(err)
        this.closeFullScreen(ing)
      })
    },
  },
  mounted() {
    let route_params = this.$route.params.postId
    if(route_params!==undefined){
      route_params = ':'+route_params
      console.log(route_params)
    }else{
      route_params=''
    }
    $(document).delegate(".cancel", "click", function() {
      // var comment = document.getElementsByClassName('comment-part')[0]
      $('.comment-part').insertBefore('#comment')
      $('.cancel').remove()
    });
    this.$axios({
      method:'get',
      url:'/api/getcomments/'+this.$route.name+route_params
    }).then(res=>{
      console.log(this.$route)
      var flag = 0;
      for(let comment of res.data){
        comment.fromAvatar = "https://api.luoqianyi.top/imgs/"+comment.fromAvatar
        comment.createTime = this.getNowFormatDate(comment.createTime)
        comment.content = this.analyzeEmoji(comment.content)
        if(comment.listReply!==null){
          for(let reply_comment of comment.listReply){
            reply_comment.fromAvatar = "https://api.luoqianyi.top/imgs/"+reply_comment.fromAvatar
            reply_comment.createTime = this.getNowFormatDate(reply_comment.createTime)
            reply_comment.content = this.analyzeEmoji(reply_comment.content)
            flag++;
          }
        }
      }
      this.commentList = res.data
      this.count = this.commentList.length+flag
    }).catch(err=>{
      console.log(err)
    })
  },

}
</script>

<style>
.emoji:hover{
  background-color: gainsboro;
  cursor: pointer;
}
/*@media screen and (max-width:480px){*/
/*  .el-dialog{*/
/*    width: 100%;*/
/*  }*/
/*}*/
.el-loading-mask,.is-fullscreen {
  z-index: 1;
}
.el-dialog{
  background-image: url("../../assets/images/dialog-bk.png");
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 100%;
}
.vnum{
  font-size: 28px;
  color: black;
}
.comment_list,.children{
  list-style: none;
  position: relative;
}
.comment-author .fn a{
  color: black;
  text-decoration: none;
}
.comment{
  position: relative;
}
.comment-reply{
  position: absolute;
  top: 0;
  margin-left: 45px;
}
.reply-link{
  color: red;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  /*position: absolute;*/
}
.url{
  font-weight: bold;
}
.comment-text{
  border-bottom: 1px solid lightgrey;
  margin-left: 65px;
}
a{
  cursor: pointer;
}
.delete-link{
  color: grey;
  margin-left: 5px;
}
pre{
  font-family: "华文楷体";
  font-weight: bold;
}
#reply>pre{
  margin-left: 30px;
}
</style>
