import Vue from 'vue'
import App from './App.vue'
import './plugins/element.js'
import router from './router'
import store from './store'
import axios from "axios";
import 'element-ui'
import $ from 'jquery'
import APlayer from '@moefe/vue-aplayer'
import hljs from 'highlight.js'
// 引入echarts
import 'highlight.js/styles/googlecode.css' // 样式文件

Vue.directive('highlight', function (el) {
  let blocks = el.querySelectorAll('pre code')
  blocks.forEach((block) => {
    hljs.highlightBlock(block)
  })
})
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts

import disableDevtool from'disable-devtool';
disableDevtool();

// axios.defaults.baseURL = process.env.BASE_API
axios.defaults.baseURL = 'https://api.luoqianyi.top'
// axios.defaults.baseURL = 'http://localhost:8080'
Vue.prototype.$=$
Vue.prototype.$axios = axios

// axios({
//   method: 'get',
//   url: '/api/getUser',
//   headers: {
//     token: null
//   }
// }).then(res => {
//   console.log(res);
// });

// Vue.prototype.$axios.defaults.withCredentials = true
Vue.use(APlayer)
Vue.use(axios)
//判断:当前元素是否是被筛选元素的子元素
jQuery.fn.isChildOf = function(b){
  return (this.parents(b).length > 0);
};
//判断:当前元素是否是被筛选元素的子元素或者本身
jQuery.fn.isChildAndSelfOf = function(b){
  return (this.closest(b).length > 0);
};

Vue.config.productionTip = false
//判断数组中重复元素的次数
Array.prototype.containTimes = function(){
  let obj ={};
  return this.reduce(function(time,name){
    if(name in obj){
      obj[name]++;
    }else{
      obj[name] = 1;
    }
    return obj;
  },{});
}

//从数组中随机取几个元素
Vue.prototype.getRandomArrayElements = function(arr,count){
  var shuffled = arr.slice(0), i = arr.length, min = i - count, temp, index;
  while (i-- > min) {
    index = Math.floor((i + 1) * Math.random());
    temp = shuffled[index];
    shuffled[index] = shuffled[i];
    shuffled[i] = temp;
  }
  return shuffled.slice(min);
}

//点击回到页面顶部
Vue.prototype.toTop= function(){
  document.body.scrollTop = document.documentElement.scrollTop = 0;
}
//回到页面底部
Vue.prototype.toBottom = function(i){
  let clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
  let scrollHeight = document.documentElement.scrollHeight;
  let rollheight = scrollHeight - clientHeight; //超出窗口上界的值就是底部的scrolTop的值
  document.documentElement.scrollTop += 200;
  if (document.documentElement.scrollTop + 1 <= rollheight) {//之所以+1，可以打印这两个值的日志就知道了，下面+1也是同理
    var c = setTimeout(() => this.toBottom(i), 10);//调用setTimeout是为了“回到底部”这过程不是一瞬间
  } else {
    clearTimeout(c);
  }
}

//Loading加载与关闭
Vue.prototype.openFullScreen = function (varText='Loading',targetdq='body'){
  const loading = this.$loading({
    lock: false,
    text: varText,
    spinner: 'el-icon-loading',
    background: 'rgba(255,255,255,0.7)',
    target: document.querySelector(targetdq) // 设置加载动画区域
  });
  return loading;
}
Vue.prototype.closeFullScreen = function(loading){
  loading.close();
}

/**
 全局调用方法：
 type:'日期'
 转换时间的格式为Y-M-D
 type:'日期时间'
 转换日期的时间的格式为 Y-M-D d:h:m
 type:'日期时间'
 转换日期的时间的格式为 Y/M/D d:h:m
 */
Vue.prototype.getNowFormatDate = function(dateData,type='Y/M/D h:m:s') {

  let date = new Date(dateData)
  let y = date.getFullYear()
  let m = date.getMonth() + 1
  m = m < 10 ? ('0' + m) : m
  let d = date.getDate()
  d = d < 10 ? ('0' + d) : d
  let h = date.getHours()
  h = h < 10 ? ('0' + h) : h
  let minu = date.getMinutes()
  minu = minu < 10 ? ('0' + minu):minu
  let sec = date.getSeconds()
  sec = sec <10?('0'+sec):sec
  if(type==='Y-M-D')
    return y + '-' + m + '-' + d
  if(type==='Y/M/D')
    return y +'/' + m +'/' + d
  if(type==='Y-M-D h:m:s')
    return y + '-' + m +'-'+ d +' '+h+':'+minu+":"+sec
  if(type==='Y/M/D h:m:s')
    return y+ '/' + m + '/' + d +' '+h+':'+minu+":"+sec
};
Vue.prototype.$axios.interceptors.request.use(config => {
  // 可以统一设置请求头
  config.url = config.url.replace('api/','')
  //alert(config.url)
  return config
})

Vue.prototype.$axios.interceptors.response.use(res=>{
  if(res.data.status=="1000"){
    localStorage.clear();
  }
  return res;
},error => {
  if (error && error.response) {
    switch (error.response.status) {
      case 404:
        router.push({name:'page404'});
        error.message = '请求出错(404)'
        break;

      case 500:
        router.push({ name:'page500'});
         error.message = '服务器错误(500)';
        break;

      default: error.message = `连接出错(${error.response.status})!`;
    }
  }
  return Promise.reject(error);
})

/*
  自定义waves指令，在需要的标签上添加即可
 */
Vue.directive('waves', {
  bind: function(el, binding) {
    el.classList.add('waves-effect');
    let modifiers = Object.keys(binding.modifiers)[0], duration = +binding.expression || 200;
    !!modifiers && el.classList.add(`waves-${modifiers}`);
    function convertStyle(obj) {
      let style = '';
      for (let a in obj) {
        if (Object.prototype.hasOwnProperty.call(obj,a)) {
          style += `${a}:${obj[a]};`
        }
      }
      return style;
    }

    el.addEventListener('mousedown', function(e) {
      let ripple = document.createElement('div');
      ripple.classList.add('waves-ripple');
      el.appendChild(ripple);

      let styles = {
        left: `${e.layerX}px`,
        top: `${e.layerY}px`,
        opacity: 1,
        transform: `scale(${(el.clientWidth / 100) * 10})`,
        'transition-duration': `${duration}ms`,
        'transition-timing-function': 'ease-in-out'
      };
      ripple.setAttribute('style', convertStyle(styles));
      setTimeout(function() {
        ripple.setAttribute('style', convertStyle({
          opacity: 0,
          transform: styles.transform,
          left: styles.left,
          top: styles.top,
        }));
        setTimeout(function() {
          ripple && el.removeChild(ripple);
        }, duration * 3);
      }, duration);
    });
  },
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
