<template>
<div>
<!--  <h1>This is a tag page</h1>-->
  <div class="tabs-bar" style="margin-top: 0;">
   <nav class="tags-list" style="margin-left: 10%">
     <router-link v-waves @click.native="getAll()" to="all" style="-webkit-order:-1;order:-1" class="tags-list-item waves-effect waves-button waves-light active" id="all">
       全部
     </router-link>
    <router-link v-waves :id="tag" @click.native="getArticle(tag)" :to="tag" v-for="(tag,index) in to" :key="index" tag="a" style="-webkit-order:-1;order:-1" class="tags-list-item waves-effect waves-button waves-light ">
      {{tag}}
    </router-link>
   </nav>
    <!-- PC show more-->
    <div class="tags-list-more">
      <a href="javascript:;" @click="showMore()" onclick="BLOG.tabBar(this)" class="action tags-list-item waves-effect waves-circle waves-light"><i class="icon el-icon-more"></i></a>
    </div>
  </div>

  <div class="container body-wrap fade in">
    <div v-for="(item,index) in tagSort" :key="index">
      <h3 class="archive-separator" :id="'tag-'+item.tag" >{{item.tag}}({{item.sumArticle}})</h3>
      <div class="waterfall in" :style="'height:'+ 175*(parseInt((item.sumArticle+1)/2))+'px'" >
        <div :style="'top:'+(parseInt(id/2))*175+'px;left:'+(id%2==1)*50+'%'" class="waterfall-item" v-for="(list,id) in item.articlList" :key="id">
          <article class="article-card archive-article">
            <div class="post-meta">
              <time class="post-time" :title="list.created" :datetime="list.created"  itemprop="datePublished">{{list.created}}</time>
              <ul class="article-category-list">
                <li class="article-category-list-item">
                  <a class="article-category-list-link" :href="'/categories/'+list.category">{{list.category}}</a>
                </li>
              </ul>
            </div>
            <h3 class="post-title" itemprop="name">
              <a class="post-title-link" :href="'/post/'+list.aid">{{list.title}}</a>
            </h3>
            <div class="post-footer">

              <ul class="article-tag-list" itemprop="keywords">
                <li class="article-tag-list-item" v-for="(index,t) in list.tags" :key="index"><router-link @click.native="getArticle(list.tags[t])" class="article-tag-list-link" :to="'/tags/'+list.tags[t]" rel="tag">{{list.tags[t]}}</router-link></li>
              </ul>

            </div>

          </article>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "tags",
  data(){
    return{
      to:[],
      tagSort:[
        {
          tag:'加载中',
          sumArticle:'...',
          articlList:[
          ]
        },
      ],
      tag:'all'
    }
  },
  methods:{
    getArticle(tag){
      this.$axios({
        url:'/api/tags/'+tag,
        method: 'get'
      }).then(res=>{
        console.log(this.$route.params.tagName)
        this.tagSort = [{tag:'',sumArticle: '',articlList: []}]
        this.tagSort[0].tag = tag
        this.tagSort[0].sumArticle = res.data.length
        this.tagSort[0].articlList = res.data
      }).catch(err=>{
        console.log(err)
        this.$router.push({ name:'page500'})
      })
      $('#all').removeClass('active');
      $('#'+tag).addClass('active')
    },
    getAll(){
      this.$axios({
        url:'/api/tags/all',
        method:'get',
      }).then(res=>{
        this.tagSort = []
        this.to = res.data.all;
        for (const resKey in res.data) {
          if(resKey!=='all'&&resKey!=='AllArticle'){
            let len = res.data[resKey][0].length
            let tagList = {}
            tagList.tag = resKey
            tagList.sumArticle = len
            tagList.articlList = res.data[resKey][0]
            if(tagList.sumArticle>0){
              this.tagSort.unshift(tagList)
            }
          }

        }
      }).catch(err=>{
        console.log(err)
        this.$router.push({ name:'page500'})
      })
    },
    showMore(){
      if($('.tabs-bar').hasClass('expand')){
        $('.tabs-bar').removeClass('expand');
      }else{
       $('.tabs-bar').addClass('expand');
      }
    }
  },
  mounted() {
    this.$store.state.v = '标签'
    this.$axios({
      url:'/api/tags/all',
      method:'get'
    }).then(res=>{
      this.to = res.data.all;
    })
    this.$axios({
      url:'/api/tags/'+this.$route.params.tagName,
      method:'get',
    }).then(res=>{
      if(this.$route.params.tagName==='all'){
        this.tagSort = []
        this.to = res.data.all;
        for (const resKey in res.data) {
          if(resKey!=='all'&&resKey!=='AllArticle'){
            let len = res.data[resKey][0].length
            let tagList = {}
            tagList.tag = resKey
            tagList.sumArticle = len
            tagList.articlList = res.data[resKey][0]
            if(tagList.sumArticle>0){
              this.tagSort.unshift(tagList)
            }
          }

        }
      }else{
        this.tagSort = [{tag:'',sumArticle: '',articlList: []}]
        this.tagSort[0].tag = this.$route.params.tagName
        this.tagSort[0].sumArticle = res.data.length
        this.tagSort[0].articlList = res.data
      }
    }).catch(err=>{
      console.log(err)
      this.$router.push({ name:'page500'})
    })
  },
}
</script>

<style>

</style>
