<template>
  <div class="home">
    <div id="loading" class="active"></div>
    <aside id="menu">
      <div class="inner flex-row-vertical">
        <a v-waves href="javascript:;" class="header-icon waves-effect waves-circle waves-light" id="menu-off"><i
            class="icon icon-lg el-icon-close"></i></a>
        <div class="brand-wrap">
          <div class="brand">
            <a href="/" class="avatar waves-effect waves-circle waves-light"><img src="../assets/images/avatar.jpg"
                                                                                  alt="avatar"/></a>
            <hgroup class="introduce">
              <h5 class="nickname">{{ name }}</h5>
              <a :href="'mailto:' + email" :title="email" class="mail">{{ email }}</a>
            </hgroup>
          </div>
        </div>
        <div class="scroll-wrap flex-col">
          <ul class="nav">
            <router-link
                @click.native="changeTitle(sides.v, sides.motto)"
                active-class="active"
                v-for="sides in link_views"
                :to="'/' + sides.path + '/' + sides.prams"
                tag="li"
                v-waves
                :key="sides.id"
                class="waves-block waves-effect"
            >
              <i :class="'icon' + ' icon-lg ' + sides.icon"></i>
              <span style="margin-left: 50px">{{ sides.v }}</span>
            </router-link>
          </ul>
        </div>
        <a href="/login" id="admin" class="waves-circle" v-waves>
          <i class="el-icon-setting icon icon-lg"></i>
          管理
        </a>
      </div>
    </aside>
    <main id="main">
      <!--      顶栏头部-->
      <header class="top-header" id="header">
        <div class="flex-row">
          <a v-waves href="javascript:;" class="header-icon waves-effect waves-circle waves-light on"
             id="menu-toggle"><i class="icon icon-lg el-icon-s-unfold"></i></a>
          <div class="flex-col header-title ellipsis">{{ v }}</div>

          <div class="search-wrap" id="search-wrap">
            <a href="javascript:;" class="header-icon waves-effect waves-circle waves-light" id="back"><i
                class="icon icon-lg icon-chevron-left"></i></a>
            <input @keyup.enter="search_article" type="text" id="key" class="search-input" autocomplete="on"
                   placeholder="输入感兴趣的关键字，回车搜索"/>
            <a href="javascript:;" class="header-icon waves-effect waves-circle waves-light" id="search"><i
                class="icon icon-lg icon-search"></i></a>
          </div>

          <el-tooltip v-if="!islogin" class="item" effect="dark" content="登陆后才能查看消息~" placement="bottom">
            <a href="javascript:;" class="header-icon waves-effect waves-circle waves-light" id="message"><i
                class="icon icon-lg el-icon-message-solid"></i></a>
          </el-tooltip>
          <el-dropdown v-else trigger="click" @click.native="getComment">
						<span class="el-dropdown-link">
							<a class="header-icon waves-effect waves-circle waves-light">
								<i class="icon icon-lg el-icon-message-solid"><el-badge style="margin-bottom: 10px"
                                                                        :is-dot="islogin"></el-badge></i>
							</a>
						</span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item disabled class="clearfix" style="color: #ee6653">
                  <strong>公告：</strong>
                  欢迎光临，本站如有不足，欢迎留言！
                </el-dropdown-item>
                <el-dropdown-item divided v-for="(item, index) in infos" :key="index" style="margin-left: 10px">
                  <strong style="color:darkcyan;">{{ item.user }}</strong>
                  在《
                  <a :href="'/' + item.title_link" style="color: blue;text-decoration: underline">{{ item.title }}</a>
                  》中回复了您的评论
                  <el-badge is-dot class="item"></el-badge>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
          <el-tooltip v-if="!islogin" class="item" effect="dark" content="登陆后的世界更精彩,请登陆~" placement="bottom">
            <a @click.prevent="regAndLogin" rel="noopener" class="header-icon waves-effect waves-circle waves-light"
               id="login">
              <i class="icon icon-lg icon-key"></i>
            </a>
          </el-tooltip>
          <el-tooltip v-else class="item" effect="dark" :content="'欢迎您,' + guest" placement="bottom">
            <a>
              <el-dropdown trigger="click">
								<span class="el-dropdown-link">
									<el-avatar style="margin-top: 12px" shape="square" :size="'small'" :src="guest_img"></el-avatar>
									<i class="el-icon-arrow-down el-icon--right"></i>
								</span>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item @click.native="quit" icon="el-icon-switch-button">退出登录</el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </a>
          </el-tooltip>
        </div>
      </header>
      <!--      内容栏头部-->
      <header class="content-header index-header">
        <div class="container fade-scale in">
          <h1 class="title" v-show="v !== '关于我'" style="visibility: visible !important;">{{ v }}</h1>
          <h5 class="subtitle" id="subtitle">{{ motto }}</h5>
        </div>
      </header>

      <keep-alive v-if="showView">
        <transition name="el-fade-in-linear">
          <router-view :key="$route.fullPath"></router-view>
        </transition>
      </keep-alive>
      <footer class="footer">
        <div class="top">
          <p>
						<span id="lc_counter_container_site_uv">
							<i class="icon icon-user"></i>
							<span id="lc_counter_value_site_uv">{{ Custom_views }}</span>
						</span>
            <span id="lc_counter_container_site_pv">
							<i class="icon icon-eye"></i>
							<span id="lc_counter_value_site_pv">{{ Total_views }}</span>
						</span>
          </p>
          <p><a id="jinrishici-sentence" href="https://www.jinrishici.com/"  rel="noopener"></a></p>
          <!--Todo: 考虑重构为插件 -->
          <p>
						<span>
							<a href="#" target="_blank" class="rss" title="rss"><i class="icon icon-lg icon-rss"></i></a>
						</span>

            <span>
							<a href="https://github.com/luoqianyi" target="_blank" rel="license noopener" title="Github"><i
                  class="icon icon-lg icon-github"></i></a>
						</span>
            <!--            <span>-->
            <!--                    <a href="https://weibo.com/1278609231/profile" target="_blank" rel="license noopener" title="微博">-->
            <!--                        <i class="icon icon-lg icon-weibo"></i>-->
            <!--                    </a>-->
            <!--                </span>-->
            <span>
							<a href="https://www.douban.com/people/200113090/" target="_blank" rel="license noopener" title="豆瓣"><i
                  class="icon icon-lg icon-douban"></i></a>
						</span>
            <span>
							<a href="https://www.zhihu.com/people/qian-yi-12-5-48" target="_blank" rel="license noopener" title="知乎">
								<i class="icon icon-lg icon-zhihu-square"></i>
							</a>
						</span>
            <span>
							<a href="https://blog.csdn.net/qq_45743005" target="_blank" rel="license noopener" title="CSDN"><i
                  class="icon icon-lg">C</i></a>
						</span>
            <span>
							<a href="https://y.qq.com/n/m/detail/taoge/index.html?id=7096178931" target="_blank"
                 rel="license noopener" title="QQ音乐">
								<i class="icon icon-lg icon-qqyinyue"></i>
							</a>
						</span>
          </p>
        </div>
        <div class="bottom">
          <p>
            <span>{{ name }} &copy; 2022 Copyright | </span>
            <a href="https://beian.miit.gov.cn" target="_blank">豫ICP备2021022950号-2</a>
          </p>
        </div>
      </footer>
    </main>
    <div class="mask" id="mask"></div>
    <a href="javascript:;" id="gotop" class="waves-effect waves-circle waves-light"><span
        class="icon icon-lg icon-chevron-up"></span></a>
    <div class="search-panel" id="search-panel">
      <ul class="search-result" id="search-result">
        <li v-show="search_res.length!==0" class="item" v-for="(item,index) in search_res" :key="index">
          <a :href="item.link" class="waves-block waves-effect" v-waves>
            <div class="ellipsis" v-html="item.title"></div>
            <div class="flex-row flex-middle">
              <div class="tags ellipsis">
                <span v-for="(i,id) in item.tags" :key="id" v-html="'#'+i+'#'"></span>
              </div>
              <time class="flex-col time">{{ item.create_time }}</time>
            </div>
          </a>
        </li>
        <li v-show="search_res.length===0" class="tips"><i class="icon icon-coffee icon-3x"></i>
          <p>No Results!</p></li>
      </ul>
    </div>
    <login-and-reg :show.sync="show"></login-and-reg>
  </div>
</template>
<script>
const LoginAndReg = () => import("@/views/commons/loginAndReg")
export default {
  name: 'Home',
  components: {LoginAndReg},
  provide() {
    return {
      refreshView: this.refreshView
    };
  },
  data() {
    return {
      showView: true, //用于点击当前页的route时，刷新当前页
      name: '落迁忆',
      email: '3228077562@qq.com',
      motto: '天空黑暗到一定程度，星辰就会熠熠生辉。',
      h1: '',
      checked: true,
      loading: false,
      flag: true,
      Custom_views: '',
      Total_views: '',
      link_views: [
        {
          v: '主页',
          path: 'index',
          icon: 'el-icon-s-home',
          motto: '天空黑暗到一定程度，星辰就会熠熠生辉。',
          prams: ''
        },
        {
          v: '归档',
          path: 'archives',
          icon: 'el-icon-s-flag',
          motto: '',
          prams: ''
        },
        {
          v: '标签',
          path: 'tags',
          icon: 'el-icon-collection-tag',
          motto: '',
          prams: 'all'
        },
        {
          v: '分类',
          path: 'categories',
          icon: 'el-icon-box',
          motto: '',
          prams: 'all'
        },
        {
          v: '影单',
          path: 'movies',
          icon: 'el-icon-film',
          motto: '',
          prams: ''
        },
        {
          v: '作品',
          path: 'works',
          icon: 'el-icon-s-opportunity',
          motto: '',
          prams: ''
        },
        {
          v: '关于我',
          path: 'about',
          icon: 'el-icon-s-custom',
          motto: '',
          prams: ''
        }
      ],
      // v:'',
      show: false,
      islogin: false,
      guest: '',
      guest_img: '',
      infos: [
        {
          title: '',
          user: '',
          title_link: ''
        }
      ],
      search_res: [
        {
          aid: 0,
          create_time: '2021/04/10',
          title: '关于我',
          link: '/about',
          tags: ["推荐阅读", "个人介绍"]
        }
      ]
    };
  },
  methods: {
    refreshView() {
      this.showView = false;
      this.$nextTick(() => {
        this.showView = true;
      });
    },
    submitClick: function () {
      var _this = this;
      this.loading = true;
      this.$axios({
        method: 'post',

        url: '/api/login?name=' + this.loginForm.name + '&password=' + this.loginForm.password
      }).then(resp => {
        _this.loading = false;
        var data = resp.data;
        if (resp.status == 200) {
          // eslint-disable-next-line no-debugger
          debugger;
          //成功
          var code = data;
          if (code == true) {
            _this.$alert('登录成功!', '成功!');
          } else {
            _this.$alert('登录失败!', '失败!');
          }
        } else {
          //失败
          _this.$alert('登录失败!', '失败!');
        }
      });
    },
    changeTitle(title, motto) {
      console.log(this.$route);
      this.refreshView();
      this.$store.commit('setV', title);
      this.motto = motto;
      this.h1 = title;
      $('.title').css({
        visibility: 'visible'
      })
    },
    regAndLogin() {
      this.show = true;
    },
    quit() {
      this.islogin = false;
      localStorage.setItem('token', '');

      var that = this;
      this.openFullScreen('退出登陆中');
      setTimeout(function () {
        that.$router.go(0);
      }, 500);
    },
    getComment() {
      this.$axios({
        method: 'get',
        url: '/api/getReplyMe/' + this.guest
      })
          .then(res => {
            this.infos = [];
            if (res.data.length === 0) {
              this.infos = '';
            }
            for (let i = 0; i < res.data.length; i++) {
              let title = res.data[i][0];
              let title_link = res.data[i][1].ownerId.replace(':', '/');
              let user = res.data[i][1].fromName;
              let info = {title_link, title, user};
              this.infos.push(info);
            }
          })
          .catch(err => {
            console.log(err);
          });
    },
    search_article() {
      var keyword = $('#key').val()
      if (!keyword.match(/^[? ]*$/)) {
        var flag = this.openFullScreen('搜索中', '#search-panel')
        $('#search-panel').addClass('in')
        this.$axios({
          method: 'get',
          url: '/api/search/' + keyword
        }).then(res => {
          this.closeFullScreen(flag);
          if (res.data.length !== 0) {
            this.search_res = []
            res.data.forEach((key) => {
              let Tag = []
              if (typeof key.tags === "string") {
                Tag.push(key.tags)
              } else {
                Tag = key.tags
              }
              this.search_res.push(
                  {
                    aid: key.aid,
                    create_time: this.getNowFormatDate(key.created, 'Y/M/D'),
                    title: key.title,
                    link: '/post/' + key.aid,
                    tags: Tag
                  }
              )
            })
          } else {
            this.search_res = []
          }
        }).catch(err => {
          console.log(err)
        })
      }
    }
  },
  mounted() {
    // $('#loading').animate({left:'0%'},500,function(){
    //   $('#loading').css('left','-100%');
    // })
    $('#menu-off').click(function () {
      $('#menu').addClass('hide');
    });
    $('#menu-toggle').click(function () {
      $('#menu').removeClass('hide');
    });
    $('#search').click(function () {
      $('#search-wrap').addClass('in');
    });
    $('#back').click(function () {
      $('#search-wrap').removeClass('in');
    });
    $('body').click(function () {
      $('#search-panel').removeClass('in')
    })
    let token = localStorage.getItem('token');
    if (token === '') {
      this.islogin = false;
    } else {
      this.$axios({
        method: 'get',
        url: '/api/getUser',
        headers: {
          token: token
        }
      })
          .then(res => {
            if (res.data !== '') {
              this.guest = res.data.username;
              this.guest_img = 'https://api.luoqianyi.top/imgs/' + res.data.avatar;
            } else {
              this.islogin = false;
            }
          })
          .catch(err => {
            console.log(err);
          });
      this.islogin = true;
    }

    this.$axios({
      url: '/api/addViews',
      method: 'post',
      headers: {
        route: 'Visit_users'
      }
    }).then(res => {
      this.Custom_views = res.data
    });
    this.$axios({
      url: '/api/addViews',
      method: 'post',
      headers: {
        route: 'Visit_views'
      }
    }).then(res => {
      this.Total_views = res.data
    })
  },
  computed: {
    v() {
      return this.$store.getters.getV;
    }
  },
  updated() {
    document.querySelector('#subtitle').innerText=document.querySelector('#jinrishici-sentence').innerText
  }
};
</script>
<style src="../assets/styles/style.css"/>
<style src="../../public/styles/animate.min.css"/>
<style>
#admin {
  text-decoration: none;
  margin-bottom: 5px;
  width: 100px;
  height: 60px;
  line-height: 60px;
  position: absolute;
  right: 0;
}

.brand-wrap {
  background-image: url('../assets/images/brand.jpg');
}

#page-nav {
  margin: 30px 0 0;
  text-align: center;
}

.post-list {
  display: block;
}
</style>
