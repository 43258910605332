<template>
  <div>
    <!--  <h1>This is an movie page</h1>-->
    <div class="container body-wrap">
      <article class="page-article fade in" itemprop="blogPage">
        <div class="post-content page-content" id="page-content" itemprop="pageContent">
          <blockquote>
            <p>
              如果有多一张船票，你会不会跟我一起走？ —— 《花样年华》
            </p>
          </blockquote>
          <article class="card page-article fade in" itemprop="blogPage">
            <div class="douban-tabs">
              <a class="douban-tab" id="douban-tab1" href="javascript:;" rel="external">在看 ({{watching.totalSize}})</a>
              <a class="douban-tab" id="douban-tab2" href="javascript:;" rel="external">想看 ({{wantToWatch.totalSize}})</a>
              <a class="douban-tab" id="douban-tab3" href="javascript:;" rel="external">已看 ({{watched.totalSize}})</a>
            </div>
            <div>
              <div id="douban-item1" class="animated fadeInDown" >
                <div class="douban-item" v-for="(item,id) in watching.movies_list" :key="id">
                  <div class="douban-picture"><img :src="item.img"
                                                        :data-src="item.img"
                                                        referrerPolicy="no-referrer"/></div>
                  <div class="douban-info">
                    <div class="douban-title">
                      <a target="_blank" :href="'#'">{{item.name}}</a>
                    </div>
                    <div class="douban-meta">
                      {{item.meta}}
                    </div>
                  </div>
                </div>
                <div class="douban-pagination">
                  <pageination
                      :total-data="watching.totalSize"
                      :prev_text="'<<'"
                      :next_text="'>>'"
                      :pagesize="each_page"
                      @pageBar="handleItem1"
                  >
                  </pageination>
                </div>
              </div>
              <div id="douban-item2" class="animated fadeInDown"  >
                <div class="douban-item" v-for="(item,id) in wantToWatch.movies_list" :key="id">
                  <div class="douban-picture"><img :src="item.img"
                                                        :data-src="item.img"
                                                        referrerPolicy="no-referrer"/></div>
                  <div class="douban-info">
                    <div class="douban-title">
                      <a target="_blank" :href="item.link">{{item.name}}</a>
                    </div>
                    <div class="douban-meta">
                      {{item.meta}}
                    </div>
                  </div>
                </div>
                <div class="douban-pagination">
                  <pageination
                      :total-data="wantToWatch.totalSize"
                      :prev_text="'<<'"
                      :next_text="'>>'"
                      :pagesize="each_page"
                      @pageBar="handleItem2"
                  >
                  </pageination>
                </div>
              </div>
              <div id="douban-item3" class="animated fadeInDown" >
                <div class="douban-item" v-for="(item,id) in watched.movies_list" :key="id">
                  <div class="douban-picture"><img :src="item.img"
                                                        :data-src="item.img"
                                                        referrerPolicy="no-referrer"/></div>
                  <div class="douban-info">
                    <div class="douban-title">
                      <a target="_blank" :href="item.link">{{item.name}}</a>
                    </div>
                    <div class="douban-meta">{{item.meta}}</div>
                    <div class="douban-comments">
                      {{item.content}}
                    </div>
                  </div>
                </div>
                <div class="douban-pagination">
                  <pageination
                      :total-data="watched.totalSize"
                      :prev_text="'<<'"
                      :next_text="'>>'"
                      :pagesize="each_page"
                      @pageBar="handleItem3"
                  >
                  </pageination>
                </div>
              </div>
            </div>
          </article>
          <div class="card page-about-me flex-row">
          <a href="/" class="avatar waves-effect waves-circle waves-light"><img src="../assets/images/avatar.jpg"></a>
          <div class="content flex-col">
            <p><strong>落迁忆</strong></p>
            <p>纵有疾风起，人生不言弃</p>
            <p>星光不问赶路人，时光不负有心人！。</p>
          </div>
         <share-feb></share-feb>
        </div>
        </div>
        <award></award>
      </article>
    </div>
  </div>
</template>

<script>
const pageination = ()=>import("@/views/commons/pageination")
const shareFeb = ()=>import("@/views/commons/shareFeb")
const award = ()=>import("@/views/commons/award")
export default {
  name: "movies",
  data(){
    return{
      each_page:4,
      watched:{
        totalSize:0,
        movies_list:[],
        total_movies:[
            {
              name:'',
              img:'',
              content:'',
              stars:''
            },
        ]
      },
      wantToWatch:{
        totalSize:0,
        movies_list:[],
        total_movies:[
          {
            name:'',
            img:'',
            content:''
          },
        ]
      },
      watching:{
        totalSize:0,
        movies_list:[],
        total_movies:[
          {
            name:'',
            img:'',
            content:''
          },
        ]
      }
    }
  },
  components:{
    shareFeb,
    award,
    pageination
  },
  methods:{
    handleItem1(val){
      this.$axios({
        method:'post',
        url:'/api/movies/watching',
        data:val
      }).then(res=>{
        this.watching.movies_list = res.data
      }).catch(err=>{
        console.log(err)
        this.$router.push({ name:'page500'})
      })
    },
    handleItem2(val){
      this.$axios({
        method:'post',
        url:'/api/movies/want',
        data:val
      }).then(res=>{
        this.wantToWatch.movies_list = res.data
      }).catch(err=>{
        console.log(err)
        this.$router.push({ name:'page500'})
      })
    },
    handleItem3(val){
      this.$axios({
        method:'post',
        url:'/api/movies/watched',
        data:val
      }).then(res=>{
        this.watched.movies_list = res.data
      }).catch(err=>{
        console.log(err)
        this.$router.push({ name:'page500'})
      })
    }
  },
  mounted() {
    this.$store.state.v = '影单'
    $(function () {
      $('#douban-tab3').addClass('douban-active');
      $('#douban-item1,#douban-item2').hide();
      $('.douban-tab').click(function(){
        $('.douban-tab').removeClass('douban-active')
        $(this).addClass('douban-active');
        if($(this).attr('id')=='douban-tab1'){
          $('#douban-item1').show();
          $('#douban-item3').hide();
          $('#douban-item2').hide();
        }else if($(this).attr('id')=='douban-tab2'){
          $('#douban-item2').show();
          $('#douban-item1').hide();
          $('#douban-item3').hide();
        }else{
          $('#douban-item3').show();
          $('#douban-item1').hide();
          $('#douban-item2').hide();
        }
      });

    });
    this.$axios({
      url:'/api/movies/',
      method:'get'
    }).then(res=>{
      //想看
      this.wantToWatch.totalSize = res.data.wantToWatch.length
      this.wantToWatch.movies_list = res.data.wantToWatch.slice(0,this.each_page)
      //已看
      this.watched.totalSize = res.data.watched.length
      this.watched.movies_list = res.data.watched.slice(0,this.each_page)
      //在看
      this.watching.totalSize = res.data.watching.length
      this.watching.movies_list = res.data.watching.slice(0,this.each_page)
    }).catch(err=>{
      console.log(err)
      this.$router.push({ name:'page500'})
    })
  },
}
</script>

<style scoped>
.douban-tabs {
  margin-bottom: 15px;
  margin-top: 15px;
  text-align: center;
}

.douban-tab {
  color: #303f9f;
  padding: 5px;
}

.douban-tab:hover {
  color: #ff4081;
}

.douban-active {
  /* background: #657b83; */
  /* color: #fff; */
  color: #ff4081;
}

.douban-item {
  padding-bottom: 10px;
  position: relative;
  clear: both;
  min-height: 170px;
  padding: 10px 0;
  border-bottom: 1px #ddd solid;
}

@media screen and (max-width: 600px) {
  .douban-item {
    width: 100%;
  }
}

.douban-picture {
  position: absolute;
  left: 0;
  top: 10px;
  width: 100px;
}

.douban-info {
  padding-left: 120px;
}

.douban-meta {
  font-size: 12px;
  padding-right: 10px;
}

.douban-comments {
  font-size: 12px;
}

.douban-pagination {
  margin-top: 15px;
  text-align: center;
  margin-bottom: 10px;
  height: 30px;
}

.douban-button {
  padding: 5px;
}

.douban-button:hover {
  /* background: #657b83; */
  /* color: #fff; */
  color: #ff4081;
}
</style>
