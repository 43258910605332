<template>
<div>
  <div class="container body-wrap fade in">
    <div  v-for="(item,index) in DateArticleList" :key="index">
      <h3 class="archive-separator">{{item.year}}-{{item.month}} ({{item.articleList===null ? '居然没有文章啊啊~' : item.articleList.length}})</h3>
      <div class="waterfall in"  :style="'height:'+175*(parseInt(((item.articleList===null ? 0 : item.articleList.length)+1)/2))+'px'">
        <div class="waterfall-item" :style="'top:'+(parseInt(id/2))*175+'px;left:'+(id%2==1)*50+'%'" v-for="(list,id) in item.articleList" :key="id">
          <article class="article-card archive-article">
            <div class="post-meta">
              <time class="post-time" :title="list.created" :datetime="list.created"  itemprop="datePublished">{{list.created}}</time>
              <ul class="article-category-list"><li class="article-category-list-item"><a class="article-category-list-link" :href="'/categories/'+list.category">{{list.category}}</a></li></ul>
            </div>
            <h3 class="post-title" itemprop="name">
              <a class="post-title-link" :href="'/post/'+list.aid">{{list.title}}</a>
            </h3>
            <div class="post-footer">
              <ul class="article-tag-list" itemprop="keywords">
                <li class="article-tag-list-item" v-for="(index,t) in list.tags" :key="index"><a  :href="'/tags/'+list.tags[t]" class="article-tag-list-link" rel="tag">{{list.tags[t]}}</a></li>
              </ul>
            </div>
          </article>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "archives",
  data(){
  return{
      currentPage:1,
      eachPage:[[]],
      DateArticleList:[
        {
          year:'加载中',
          month:'',
          articleList:[]
        }
      ]
    }
  },
  methods:{
  },
  mounted: function () {
    this.$store.state.v = '归档'
    this.$axios({
      method: 'get',
      url: '/api/archives'
    }).then(res => {
      this.DateArticleList = res.data
      this.DateArticleList.sort(function(a,b){
        if(parseInt(a.year)>parseInt(b.year)) return -1;
        else if(parseInt(a.year)<parseInt(b.year)) return 0;
        else{
          if(parseInt(a.month)>parseInt(b.month)) return -1;
          else if(parseInt(a.month)<parseInt(b.month)) return 0;
          else return 0;
        }
      })
    }).catch(err => {
      console.log(err)
      this.$router.push({ name:'page500'})
    })
  }
}
</script>

<style scoped>

</style>
