import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    store_tagSort:[
      {
        tag:'',
        sumArticle:'',
        articlList:[
        ]
      }
    ],
    tag:'all',
    v:'主页',
    userForm: {
      account: 'luoqianyi',
      password: '123456'
    },
    flag:false
  },
  mutations: {
      setTagSort(state,value){
        state.store_tagSort = value
      },
      setTag(state,value){
        state.tag = value
      },
     setV(state,value){
        state.v = value
     }
    },
  getters:{
    getTagSort(state){
      return state.store_tagSort
    },
    getTag(state){
      return state.tag
    },
    getV(state){
      return state.v
    }
  },
  actions: {
    setTagSortValue({commit},value){
      commit('setTagSort',value)
    },
    setTag({commit},value){
      commit('setTag',value)
    },
    setVdata({commit},value){
      commit('setV',value)
    },
  },
  modules: {
  }
})
